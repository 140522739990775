import React from "react"

function SliderSetting({min=0, max=100, unit='%', value, onChange}) {
    return (
        <div className='module-setting'>
            <input type='range' min={min} max={max} value={value} onChange={evt => onChange(evt.target.value)}/>
            <div className='module-setting-value'>{`${value}${unit}`}</div>
        </div>
    )
}

function ColorSetting({value, onChange}) {
    return (
        <div className='module-setting'>
            <input type='color' value={value} onChange={evt => onChange(evt.target.value)} />
        </div>
    )
}

function ButtonSetting({buttons, value, onChange}) {
    return (
        <div className='module-setting centered'>
            <div className='button-setting-buttons'>
                {buttons.map((btn, idx) => 
                    <div role='button' 
                        key={idx} 
                        className={'setting-button' + (btn.value === value ? ' selected' : '')}
                        onClick={() => onChange(btn.value)}>
                            {btn.label}
                    </div>)
                }
            </div>
        </div>
    )
}

function TableSetting({top, right, bottom, left, onChangeTop, onChangeRight, onChangeBottom, onChangeLeft, units}) {
    return (
        <div className='module-setting'>
            <div className='table-setting'>
                <table>
                    <thead><tr><th>Top</th><th>Right</th><th>Bottom</th><th>Left</th></tr></thead>
                    <tbody>
                        <tr>
                            <td><input type='number' value={top} onChange={evt => onChangeTop(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={right} onChange={evt => onChangeRight(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={bottom} onChange={evt => onChangeBottom(evt.target.value)} min={0} max={100} /></td>
                            <td><input type='number' value={left} onChange={evt => onChangeLeft(evt.target.value)} min={0} max={100} /></td>
                        </tr>
                        <tr><td>{units}</td><td>{units}</td><td>{units}</td><td>{units}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export { SliderSetting, ColorSetting, ButtonSetting, TableSetting }