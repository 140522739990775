import { WebsitesProvider } from 'contexts/websites';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import Websites from './websites';

export async function loader({params}) {
    return params;
}

function Website() {
    const loaderData = useLoaderData();

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Website</div>
                </div>
            </div>
            <WebsitesProvider>
                <Websites />
            </WebsitesProvider>
        </div>
    )
}

export default Website;