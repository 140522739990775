import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import SidePanel from 'components/sidePanel';
import ScheduleDetails from './ScheduleDetails';
import ShiftDetails from './ShiftDetails';
import { useSchedules } from 'contexts/schedules';
import { useWorkers } from 'contexts/workers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCalendarAlt, faSync, faFileDownload, faPrint } from '@fortawesome/pro-regular-svg-icons';
import { useShifts } from 'contexts/shifts';
import 'style/Schedules.scss';
import Loading from 'components/loading';
import IconButton from 'components/iconButton';
import { EventsProvider } from 'contexts/events';
import CopySchedule from './CopySchedule';
import CsvExport from 'components/csvExport';

function Schedules({eventId}) {
    const { schedulesLoading, schedules, getSchedule, getSchedules, deleteSchedules } = useSchedules();
    const { getScheduleShifts, deleteShifts, getAllShifts } = useShifts();
    const { workers } = useWorkers();
    const [scheduleId, setScheduleId] = useState('0');
    const [editingSchedule, setEditingSchedule] = useState(null);
    const [shift, setShift] = useState(null);
    const schedulePanel = useRef(null);
    const shiftPanel = useRef(null);
    const copySchedulePanel = useRef(null);

    const [csvData, setCsvData] = useState(null);
    const [csvFilename, setCsvFilename] = useState('');

    const deleteScheduleHandler = () => {
        const ids = [scheduleId];
        deleteSchedules(ids, () => {
            setScheduleId('0');
        })
    }

    const createSchedule = () => {
        setEditingSchedule(null);
        schedulePanel.current.show();
    }

    const copySchedule = () => {
        setEditingSchedule(null);
        copySchedulePanel.current.show();
    }

    const editSchedule = () => {
        let sched = getSchedule(scheduleId);
        setEditingSchedule(sched);
        schedulePanel.current.show();
    }

    const saveScheduleHandler = (schedId) => {
        setEditingSchedule(null);
        schedulePanel.current.hide();
        if (schedId) {
            setScheduleId(schedId);
        }
    }

    const copyScheduleHandler = () => {
        setEditingSchedule(null);
        copySchedulePanel.current.hide();
        getAllShifts();
    }

    const createShift = () => {
        setShift(null);
        shiftPanel.current.show();
    }

    const editShift = (shft) => {
        setShift(shft);
        shiftPanel.current.show();
    }

    const saveShiftHandler = () => {
        setShift(null);
        shiftPanel.current.hide();
    }

    const deleteShiftHandler = (shft) => {
        const ids = [shft.id];
        deleteShifts(ids);
    }

    const renderShifts = () => {
        const scheduleShifts = getScheduleShifts(scheduleId);

        let shiftDate = null;

        return scheduleShifts.map(shift => {
            let dateRow = null;
            // determine if we need to add a date row
            const sDate = moment(shift.shiftDate).format('MM/DD/YY');
            if (sDate !== shiftDate) {
                dateRow = <ShiftDate key={sDate}>{sDate}</ShiftDate>;
                shiftDate = sDate;
            }
            const shiftRow = <Shift 
                key={shift.id} 
                shift={shift} 
                onEdit={() => editShift(shift)}
                onSave={saveShiftHandler} 
                onDelete={() => deleteShiftHandler(shift)}
             />

            if (dateRow) {
                return <>{dateRow}{shiftRow}</>
            } else {
                return <>{shiftRow}</>
            }
        })
    }

    const onDownload = () => {
        var _csvData = [["date", "shift start", "worker", "email", "phone", "shirt size", "notes"]];
        const scheduleShifts = getScheduleShifts(scheduleId);

        let shiftDate = null;

        scheduleShifts.forEach(shift => {
            const sDate = moment(shift.shiftDate).format('MM/DD/YY');
            if (sDate !== shiftDate) {
                shiftDate = sDate;
            }
            const worker = workers.find(w => w.id == shift.worker);
            let shiftStart = moment(shiftDate).hour(shift.start).minute(shift.startMinute);

            _csvData.push([
                moment(shift.shiftDate).format('dddd MMMM Do YYYY'), 
                shiftStart.format('h:mm a') + ' - ' + shiftStart.add(shift.duration, 'hours').format('h:mm a'),
                (worker ? worker.fname + ' ' + worker.lname : ''),
                (worker ? worker.email : ''),
                (worker ? worker.phone : ''),
                (worker ? worker.shirt : ''),
                (worker ? worker.notes : '')
            ]);
        });

        setCsvFilename(getSchedule(scheduleId).name + '.csv');
        setCsvData(_csvData);
/*
        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
        */
    }
    const formatScheduleToHTML = () => {
        const scheduleName = getSchedule(scheduleId).name;
        const scheduleShifts = getScheduleShifts(scheduleId);

        let html = `
            <html>
            <head>
                <title>${scheduleName}</title>
                <style>
                    body { font-family: Arial, sans-serif; }
                    table { width: 100%; border-collapse: collapse; }
                    th, td { border: 1px solid #ddd; padding: 8px; }
                    th { background-color: #f2f2f2; }
                </style>
            </head>
            <body>
                <h1>${scheduleName}</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Shift</th>
                            <th>Start time</th>
                            <th>Worker</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Gift choice</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
        `;
        
        let shiftDate = null;

        scheduleShifts.forEach(shift => {
            const sDate = moment(shift.shiftDate).format('MM/DD/YY');
            if (sDate !== shiftDate) {
                shiftDate = sDate;
            }
            const worker = workers.find(w => w.id == shift.worker);
            let shiftStart = moment(shiftDate).hour(shift.start).minute(shift.startMinute);

            html += `
                <tr>
                    <td>${moment(shift.shiftDate).format('ddd MMM Do YYYY')}</td>
                    <td>${shiftStart.format('h:mm a') + ' - ' + shiftStart.add(shift.duration, 'hours').format('h:mm a')}</td>
                    <td>${worker ? worker.fname : ''} ${worker ? worker.lname : ''}</td>
                    <td>${worker ? worker.email : ''}</td>
                    <td>${worker ? worker.phone : ''}</td>
                    <td>${worker ? worker.shirt : ''}</td>
                    <td>${worker ? worker.notes : ''}</td>
                </tr>
            `;
        });
    
        html += `
                    </tbody>
                </table>
            </body>
            </html>
        `;
    
        return html;
    };

    const printHandler = () => {
        const htmlData = formatScheduleToHTML();
        const printWindow = window.open('', '_blank');
    printWindow.document.write(htmlData);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    }

    return (
        <div className='schedules-container'>
            <div className='schedules-header'>
                <IconButton icon={faSync} label='Reload schedules' onClick={getSchedules} />
                <button onClick={createSchedule}>Add schedule</button>
                <button onClick={copySchedule}>Copy schedule</button>
            </div>
            <ScheduleTiles value={scheduleId} onChange={schedId => setScheduleId(schedId)} />
            {!schedulesLoading && schedules.length === 0 ?
                <div className='no-schedule-view'><h3>Select Add schedule to create a schedule</h3></div> :
            scheduleId === '0' ? <div className='no-schedule-view'><h3>Select a schedule to edit</h3></div> : 
                <div className='schedule-view'>
                    <div className='schedule-view-header'>
                        <div><h4>{getSchedule(scheduleId).name}</h4></div>
                        <div style={{display:'flex',maxHeight:'36px'}}>
                            <IconButton icon={faPrint} onClick={printHandler} label='Print' />
                            <IconButton icon={faFileDownload} onClick={() => onDownload()} label='Download' />
                            <button onClick={createShift}>Add shift</button>
                        </div>
                    </div>
                    <div className='shifts-container'>
                        <div className='right-panel'>
                            <div className='row top'>
                                <div className='row-title'>Shifts (AM/PM)</div>
                                <div className='hours'>
                                    <div className='hour'>12</div>
                                    <div className='hour'>1</div>
                                    <div className='hour'>2</div>
                                    <div className='hour'>3</div>
                                    <div className='hour'>4</div>
                                    <div className='hour'>5</div>
                                    <div className='hour'>6</div>
                                    <div className='hour'>7</div>
                                    <div className='hour'>8</div>
                                    <div className='hour'>9</div>
                                    <div className='hour'>10</div>
                                    <div className='hour'>11</div>
                                    <div className='hour'>12</div>
                                    <div className='hour'>1</div>
                                    <div className='hour'>2</div>
                                    <div className='hour'>3</div>
                                    <div className='hour'>4</div>
                                    <div className='hour'>5</div>
                                    <div className='hour'>6</div>
                                    <div className='hour'>7</div>
                                    <div className='hour'>8</div>
                                    <div className='hour'>9</div>
                                    <div className='hour'>10</div>
                                    <div className='hour'>11</div>
                                </div>
                            </div>
                            
                            {getScheduleShifts(scheduleId).length === 0
                                ? <div className='no-shifts'><h5>Click Add shift to add shifts to this schedule</h5></div>
                                : renderShifts()
                            }
                        </div>
                    </div>
                    <div className='schedule-view-footer'>
                        <button onClick={editSchedule}>Edit Schedule</button>
                        <button className='secondary' onClick={deleteScheduleHandler}>Delete Schedule</button>
                    </div>
                </div>
            }
            <SidePanel title='Schedule' ref={schedulePanel}>
                <ScheduleDetails schedule={editingSchedule} onSave={saveScheduleHandler} eventId={eventId} />
            </SidePanel>
            <SidePanel title='Shift' ref={shiftPanel}>
                <ShiftDetails shift={shift} schedule={getSchedule(shift ? shift.schedule : scheduleId)} onSave={saveShiftHandler} />
            </SidePanel>
            <SidePanel title='Copy Schedule' ref={copySchedulePanel}>
                <EventsProvider>
                    <CopySchedule eventId={eventId} onCopy={copyScheduleHandler} />
                </EventsProvider>
            </SidePanel>
            {csvData && <CsvExport csvData={csvData} filename={csvFilename} onComplete={() => {setCsvData(null);setCsvFilename('');}}/>}
        </div>
    )
}

function ShiftDate({children}) {
    return (
        <div className='row'>
            <div className='row-title'>
                <div className='row-date'>
                    {children}
                </div>
            </div>
            <div className='date-container'></div>
        </div>
    )
}

function Shift({shift, onEdit, onDelete, onSave}) {
    const { workers } = useWorkers();
    const { schedulesLoading, getSchedule } = useSchedules();
    const { saveShift } = useShifts();
    const [worker, setWorker] = useState(shift.worker);

    useEffect(() => {
        if (shift) {
            setWorker(shift.worker);
        }
    }, [shift])

    const remove = (event) => {
        event.preventDefault();
        onDelete();
    }

    const updateWorker = (wrkr) => {
        quickSave(wrkr);
    } 

    const quickSave = (wrkr) => {
        let data = {...shift};
        if (wrkr === '0') {
            delete data.worker;
        } else {
            data.worker = wrkr;
        }
        saveShift(data, () => {
            onSave();
        });
    }

    return (
        <div className='row'>
            <div className='row-title'>
                <div className='row-label'>
                    Worker: 
                    <div className='worker-select'>
                        <select value={worker} onChange={(evt) => updateWorker(evt.target.value)}>
                            <option value='0'>Unassigned</option>
                            {workers.map(w => <option value={w.id} key={w.id}>{w.fname + ' ' + w.lname}</option>)}
                        </select>
                    </div>
                </div>
                <div className='row-actions'>
                    <FontAwesomeIcon icon={faTrash} onClick={remove} />
                </div>
            </div>
            <div className='shift-container'>
                {schedulesLoading ? <Loading /> :
                <div className='shift' 
                    role='button'
                    onClick={onEdit}
                    style={{
                        width:shift.duration*50,
                        marginLeft:(shift.start*50 + (shift.startMinute > 0 ? ((shift.startMinute/60) * 50) : 0)),
                        background:getSchedule(shift.schedule).color}} />
                    
                }
            </div>
        </div>
    )
}

function ScheduleTiles({value, onChange}) {
    const { schedulesLoading, schedules } = useSchedules();

    return (
        <>
            {schedulesLoading ? <Loading /> :
                <div className='schedule-tiles'>
                    {schedules.map(sched => 
                        <ScheduleTile 
                            key={sched.id} 
                            schedule={sched} 
                            selected={value == sched.id} 
                            onClick={id => onChange(id)} />
                    )}
                </div>
            }
        </>
    )
}

function ScheduleTile({schedule, selected, onClick}) {

    return (
        <div className={'schedule-tile-outer' + (selected ? ' selected' : '')} onClick={() => onClick(schedule.id)}>
            <div className='schedule-tile-inner'>
                <div className='schedule-icon' style={{color: schedule.color}}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <div className='schedule-name'>
                    {schedule.name}
                </div>
                <div className='schedule-shift-count'>
                    {`${schedule.shiftCount} Shifts`}
                    {schedule.shareType == 1 && <span style={{color:'red'}}> - private</span>}
                    {schedule.shareType == 3 && <span style={{color:'green'}}> - public</span>}
                </div>
            </div>
        </div>
    )
}

export default Schedules;