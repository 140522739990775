import React, { useEffect, useState } from 'react';
import { useCore } from './core';
import { publish } from 'pubsub-js';

const RegistrationsContext = React.createContext(null);

function RegistrationsProvider({dontLoadData = false, eventId, children}) {
    const { runAction } = useCore();
    const [entriesLoading, setEntriesLoading] = useState(true);
    const [entries, setEntries] = useState([]);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [fullCount, setFullCount] = useState(0);
    const [processedTotal, setProcessedTotal] = useState(0);
    const [google, setGoogle] = useState('');
    const [sorter, setSorter] = useState('car_number');
    const [series, setSeries] = useState([]);

    useEffect(() => {
        getSeries();
        getRegistrations({});
    }, []);

    const getSeries = async () => {
        if (!dontLoadData) {
            await runAction('get_registration_series', {eventId}, response => {
                setSeries(response.series);
            });
        }
    }

    const getRegistrations = async (search) => {
        if (!dontLoadData) {
            const _google = search.google ? search.google : google;
            const _orderby = search.sorter ? search.sorter : sorter;
            const _limit = search.limit ? search.limit : limit;
            const _offset = search.offset ? search.offset : 0;
            //const {limit, offset} = this.state;
            
            let data = {
                limit: _limit,
                offset: _offset,
                orderby: _orderby,
                google: _google,
                nostats: true,
                event: eventId
            };
            setEntriesLoading(true);
            await runAction('get_all', data, (response) => {
                let fullCount = response.entries.length > 0 ? response.entries[0].fullCount : 0;
                setEntries(response.entries);
                setFullCount(fullCount);
                setProcessedTotal(response.entries.length > 0 ? response.entries[0].processedTotal : 0);
                setLimit(_limit);
                setOffset(_offset);
                setGoogle(_google);
                setSorter(_orderby);
                setEntriesLoading(false);
            });
        }
    }

    const saveRegistration = async (data, isNew, callback) => {
        if (!isNew) {
            //data.car_number = registration.car_number;
            await runAction('update_entry', data, () => {
                getRegistrations({google, sorter, limit, offset});
                if (callback) {
                    callback();
                }
            });
        } else {
            await runAction('create_entry', data, response => {
                publish('success', 'Successfully created number ' + response.entryNumber + ' in class ' + response.entryClass);
                getRegistrations({});
                if (callback) {
                    callback();
                }
            });
        }
    }

    const deleteRegistrations = async (ids) => {
        await runAction('delete_entry', {ids, eventId}, response => { 
            getRegistrations({});
        });
    }

    const provider = {
        entriesLoading,
        entries,
        limit,
        offset,
        fullCount,
        processedTotal,
        getRegistrations,
        saveRegistration,
        deleteRegistrations,
        series,
    }

    return <RegistrationsContext.Provider value={provider}>{children}</RegistrationsContext.Provider>
}

function useRegistrations() {
    const context = React.useContext(RegistrationsContext);
    if (!context) {
        throw new Error('useRegistrations must be used within a RegistrationsProvider');
    }
    return context;
}

export { RegistrationsProvider, useRegistrations }