import React, { useEffect, useState } from 'react';
import {permission, hasPermission} from 'util/permission';
import {feature, hasFeature} from 'util/feature';
import {faUsers, faEnvelope, faChartLine, faMoneyBill, faPhone, faVideo, faCogs, faComment, faUserCircle, faUser, faMonitorHeartRate, faRobot, faCalendarCheck, faCoin, faTablet, faBrowser} from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from './auth';

const AppsContext = React.createContext(null);

function AppsProvider({children}) {
    const { user, tenant, group } = useAuth();
    const [apps, setApps] = useState([]);
    const [currentApp, setCurrentApp] = useState(null);

    useEffect(() => {
        if (user) {
            createAppsArray();
        }
    }, [user])

    const createAppsArray = () => {
        let apps = [];
        if (user) {
            if (tenant) {
                if ((hasFeature(tenant, feature.MEMBERSHIP) || hasFeature(tenant, feature.STORE)) && (
                    hasPermission(user, permission.MEMBERSHIP) || hasPermission(user, permission.STOREADMIN)
                )) {
                    apps.push({id: 'club', label: 'Club', route: '/admin/club', icon: faUsers});
                }
                if (hasFeature(tenant, feature.EVENT)) {
                    apps.push({id: 'events', label: 'Events', route: '/admin/events', icon: faCalendarCheck});
                }
                if (hasFeature(tenant, feature.EVENT) || hasFeature(tenant, feature.MEMBERSHIP)) {
                    apps.push({id: 'email', label: 'Email', route: '/admin/email', icon: faEnvelope});
                    //apps.push({id: 'website', label: 'Website', route: '/admin/website', icon: faBrowser});
                }
                if (group && group.chatEnabled) {
                    apps.push({id: 'chat', label: 'Group Chat', route: '/admin/groupchat', icon: faComment})
                }
                if (hasFeature(tenant, feature.PHONE)) {
                    if (hasPermission(user, permission.PHONE)) {
                        apps.push({id: 'phone', label: 'Phone', route: '/admin/phone', icon: faPhone});
                    }
                }
                if (hasFeature(tenant, feature.VIDEO)) {
                    if (hasPermission(user, permission.VIDEO)) {
                        apps.push({id: 'video', label: 'Video', route: '/admin/video', icon: faVideo});
                    }
                }
                if (hasPermission(user, permission.ACCOUNTADMIN)) {
                    apps.splice(0, 0, {id: 'dashboard', label: 'Dashboard', route: '/admin/dashboard', icon: faChartLine});
                    apps.push({id: 'billing', label: 'Finance', route: '/admin/finance/income', icon: faMoneyBill});
                    apps.push({id: 'admin', label: 'Administration', route: '/admin/admin', icon: faCogs});
                }
                if (hasPermission(user, permission.FINANCE) && apps.findIndex(a => a.id === 'billing') === -1) {
                    apps.push({id: 'billing', label: 'Finance', route: '/admin/finance/income', icon: faMoneyBill});
                }
            }
            if (hasPermission(user, permission.SYSTEMADMIN)
                    || hasPermission(user, permission.RESELLER)
                    || hasPermission(user, permission.ORGADMIN)
                    || hasPermission(user, permission.CUSTOMERSERVICE)) {
                apps.push({id: 'accounts', label: 'Accounts', route: '/admin/accounts', icon: faUser});
                if (hasPermission(user, permission.SYSTEMADMIN) || hasPermission(user, permission.CUSTOMERSERVICE)) {
                    apps.push({id: 'logins', label: 'Logins', route: '/admin/logins', icon: faUserCircle});
                    apps.push({id: 'services', label: 'Services', route: '/admin/services', icon: faRobot});
                    // merchants are deprecated - not in stripe
                    apps.push({id: 'stripe', label: 'Stripe', route: '/admin/stripe', icon: faCoin});
                    apps.push({id: 'equipmentRentals', label: 'Equipment Rentals', route: '/admin/equipmentrentals', icon: faTablet});
                }
            }
            if (hasPermission(user, permission.GROUPADMIN)) {
                apps.push({id: 'groupdashboard', label: 'Dashboard', route: '/group/dashboard', icon: faChartLine});
                apps.push({id: 'accounts', label: 'Accounts', route: '/group/accounts', icon: faUser});
                apps.push({id: 'monitoring', label: 'Monitoring', route: '/group/monitoring', icon: faMonitorHeartRate});
                apps.push({id: 'groupadmin', label: 'Administration', route: '/group/admin', icon: faUsers});
                //apps.push({id: 'reporting', label: 'Reporting', app: Reporting});
            }
        }
        setApps(apps);
        setCurrentApp(apps[0]);
    }

    const provider = {
        apps,
        currentApp, setCurrentApp,
    }

    return <AppsContext.Provider value={provider}>{children}</AppsContext.Provider>
}

function useApps() {
    const context = React.useContext(AppsContext);
    if (!context) {
        throw new Error('useApps must be used within an AppsProvider');
    }
    return context;
}

export { AppsProvider, useApps }