import React, {useEffect, useState} from 'react';
import 'style/eventPanel.scss';
import moment from 'moment';
import { formatCurrency } from '_base';
import Toggle from 'components/toggle';

function EventPanel({event, onUpdate}) {
    const [hasRegister, setHasRegister] = useState(false);
    const [hasVendor, setHasVendor] = useState(false);
    const [hasTickets, setHasTickets] = useState(false);
    const [hasWorkerSignup, setHasWorkerSignup] = useState(false);
    const [preEventLine1, setPreEventLine1] = useState('We\'re sorry, but at this time registration, vendor signup, ticket sales, and volunteer sign up for this event are unavailable.');
    const [preEventLine2, setPreEventLine2] = useState('Either check back later or reach out to the event coordinators if you have questions.');
    const [participantMessage, setParticipantMessage] = useState('Pre-registation is open now until {end_date}');
    const [vendorMessage, setVendorMessage] = useState('Vendor/Swapper signup is available now.');
    const [ticketMessage, setTicketMessage] = useState('{ticket_label} are on sale now');
    const [workerMessage, setWorkerMessage] = useState('View and signup to be a volunteer before {end_date}');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (event && !loaded) {
            setPreEventLine1(event.preEventLine1);
            setPreEventLine2(event.preEventLine2);
            setParticipantMessage(event.participantMessage);
            setVendorMessage(event.vendorMessage);
            setTicketMessage(event.ticketMessage);
            setWorkerMessage(event.workerMessage);
        }
        setLoaded(true)
    }, [event])

    useEffect(() => {
        onUpdate('preEventLine1', preEventLine1);
    }, [preEventLine1])
    
    useEffect(() => {
        onUpdate('preEventLine2', preEventLine2);
    }, [preEventLine2])

    useEffect(() => {
        onUpdate('participantMessage', participantMessage);
    }, [participantMessage])

    useEffect(() => {
        onUpdate('vendorMessage', vendorMessage);
    }, [vendorMessage])

    useEffect(() => {
        onUpdate('ticketMessage', ticketMessage);
    }, [ticketMessage])

    useEffect(() => {
        onUpdate('workerMesssage', workerMessage);
    }, [workerMessage])

    const mockHandler = () => {}

    return (
        <>
            <div className='inline-toggle'><Toggle checked={hasRegister} onChange={newVal => setHasRegister(newVal)} />Pre-registration</div>
            <div className='inline-toggle'><Toggle checked={hasVendor} onChange={newVal => setHasVendor(newVal)} />Vendor/Swap</div>
            <div className='inline-toggle'><Toggle checked={hasTickets} onChange={newVal => setHasTickets(newVal)} />Tickets</div>
            <div className='inline-toggle'><Toggle checked={hasWorkerSignup} onChange={newVal => setHasWorkerSignup(newVal)} />Worker signup</div>
            <div className='event-panel-container'>
                <div className='event-panel'>
                    <div className='event-panel-title'>{event.name}</div>
                    <div className='event-panel-image'>
                        {event.images.length > 0 ? <img src={event.images[0].imageData} /> : <div>No Image</div>}
                    </div>
                    <div className='event-panel-dates'>
                        {moment(event.startDate).isSame(event.endDate, 'day') 
                            ? moment(event.startDate).format('dddd, MMMM Do YYYY')
                            : `${moment(event.startDate).format('dddd, MMMM Do YYYY')}-${moment(event.endDate).format('dddd, MMMM Do YYYY')}`}
                    </div>
                    {hasRegister && 
                        <div className='event-panel-button'>
                            <p>{participantMessage.replace(/{end_date}/g, moment(event.reg_enddate).format('dddd, MMMM Do YYYY'))}</p>
                            {event.regMemberDiscount > 0 && <p>Members get a {formatCurrency(event.regMemberDiscount)} discount at checkout when pre-registering.</p>}
                            <button onClick={mockHandler}>Register Now {formatCurrency(event.reg_price)}</button>
                        </div>
                    }
                    {hasVendor &&
                        <div className='event-panel-button'>
                            <p>{vendorMessage}</p>
                            <button onClick={mockHandler}>Signup Now</button>
                        </div>
                    }
                    {hasTickets && 
                        <div className='event-panel-button'>
                            <p>{ticketMessage.replace(/{ticket_label}/g, event.ticketLabel.length > 0 ? event.ticketLabel : 'Tickets')}</p>
                            <button onClick={mockHandler}>Purchase Now</button>
                        </div>
                    }
                    {hasWorkerSignup && 
                        <div className='event-panel-button'>
                            <p>{workerMessage.replace(/{end_date}/g, moment(event.workerEndDate).format('dddd, MMMM Do YYYY'))}</p>
                            <button onClick={mockHandler}>Volunteer Now</button>
                        </div>
                    }
                    {!hasRegister && !hasVendor && !hasTickets && !hasWorkerSignup && 
                        <div className='event-panel-button'>
                            <p>{preEventLine1}</p>
                            <p>{preEventLine2}</p>
                        </div>
                    }
                </div>
                <div className='event-panel-settings'>
                    {hasRegister && 
                        <>
                            <label>Pre-registration instruction (128 Characters)</label>
                            <textarea 
                                style={{height: '100px'}} 
                                value={participantMessage} 
                                onChange={evt => setParticipantMessage(evt.target.value)}
                                maxLength={128}></textarea>
                        </>
                    }
                    {hasVendor &&
                        <>
                            <label>Vendor/Swap instruction (128 Characters)</label>
                            <textarea 
                                style={{height: '100px'}} 
                                value={vendorMessage} 
                                onChange={evt => setVendorMessage(evt.target.value)}
                                maxLength={128}></textarea>
                        </>
                    }
                    {hasTickets && 
                        <>
                            <label>Ticket instructions (128 Characters)</label>
                            <textarea 
                                style={{height: '100px'}} 
                                value={ticketMessage} 
                                onChange={evt => setTicketMessage(evt.target.value)}
                                maxLength={128}></textarea>
                        </>
                    }
                    {hasWorkerSignup && 
                        <>
                            <label>Worker instructions (128 Characters)</label>
                            <textarea 
                                style={{height: '100px'}} 
                                value={workerMessage} 
                                onChange={evt => setWorkerMessage(evt.target.value)}
                                maxLength={128}></textarea>
                        </>
                    }
                    {!hasRegister && !hasVendor && !hasTickets && !hasWorkerSignup && 
                        <>
                            <label>Pre-event text line 1 (256 Characters)</label>
                            <textarea 
                                style={{height: '100px'}} 
                                value={preEventLine1} 
                                onChange={evt => setPreEventLine1(evt.target.value)}
                                maxLength={256}></textarea>
                            <label>Pre-event text line 2 (128 Characters)</label>
                            <textarea 
                            style={{height: '100px'}} 
                            value={preEventLine2} 
                            onChange={evt => setPreEventLine2(evt.target.value)}
                            maxLength={128}></textarea>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default EventPanel;