const baseWebsites = {
    carClub: {
        name: 'My Car Club',
        header: {
            backgroundColor: '#000000',
            color: '#ffffff',
            logo: ''

        },
        footer: {
            backgroundColor: '#000000',
            color: '#ffffff'
        },
        pages: [
            {label: 'Home', sections: [
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [{elements: [{type: 'heading', tag: 'h1', value: 'Welcome to the club web site'}]}]
                },
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [
                        {elements: [
                            {type: 'paragraph', value: 'This is the Home page. To add elements to this page,' + 
                                'drag and drop elements on the page. Click on any element to edit it', 
                                elementStyle: {fontSize:'18', fontWeight: '400'}}
                        ]}
                    ]
                }
            ]},
            {label: 'About', sections: [
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [{elements: [{type: 'heading', tag: 'h1', value: 'About our club'}]}]
                },
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [
                        {elements: [
                            {type: 'paragraph', value: 'This is the About page. To add elements to this page,' + 
                                'drag and drop elements on the page. Click on any element to edit it', 
                                elementStyle: {fontSize:'18', fontWeight: '400'}}
                        ]}
                    ]
                }
            ]},
            {label: 'Activities', sections: [
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [{elements: [
                    {type: 'heading', tag: 'h1', value: 'Activities'}]}
                ]},
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [
                        {elements: [
                            {type: 'paragraph', value: 'This is the Activities page. To add elements to this page,' + 
                                'drag and drop elements on the page. Click on any element to edit it', 
                                elementStyle: {fontSize:'18', fontWeight: '400'}}
                        ]}
                ]}
            ]},
            {label: 'Membership', sections: [
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [{elements: [
                    {type: 'heading', tag: 'h1', value: 'Membership'}]}
                ]},
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [
                        {elements: [
                            {type: 'paragraph', value: 'This is the Membership page. To add elements to this page,' + 
                                'drag and drop elements on the page. Click on any element to edit it', 
                                elementStyle: {fontSize:'18', fontWeight: '400'}}
                        ]}
                ]}
            ]},
            {label: 'Contact', sections: [
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [{elements: [
                    {type: 'heading', tag: 'h1', value: 'Contact'}]}
                ]},
                {sectionStyle: {backgroundColor: '#ffffff', color:'#000000'}, 
                    columns: [
                        {elements: [
                            {type: 'paragraph', value: 'This is the Contact page. To add elements to this page,' + 
                                'drag and drop elements on the page. Click on any element to edit it', 
                                elementStyle: {fontSize:'18', fontWeight: '400'}}
                        ]}
                ]}
            ]}
        ]
    },
    carShow: {
        name: 'my Car Show'
    }
}

export default baseWebsites;