import React, { useRef, useState } from 'react';

const WebsiteContext = React.createContext(null);

function WebsiteProvider({website, children}) {
    const [name, setName] = useState(website.name);
    const [header, setHeader] = useState(website.header);
    const [footer, setFooter] = useState(website.footer);
    const [pages, setPages] = useState(website.pages);

    const [selectedPage, setSelectedPage] = useState(0);
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedElement, setSelectedElement] = useState(null);

    const dragType = useRef(null);

    const addPageSection = (sectionIndex, position) => {
        const newSection = {backgroundColor: '#ffffff', color:'#000000', columns:[{elements: [
            {type: 'paragraph', value: 'This is your new section', fontSize:'18', fontWeight: '400', elementStyle: {}}]}]}
        let _pages = [...pages];
        let sections = _pages[selectedPage].sections

        if (position === 'top') {
            sections.splice(sectionIndex, 0, newSection);
        } else {
            sections.splice(sectionIndex + 1, 0, newSection);
        }

        _pages[selectedPage].sections = sections;
        setPages(_pages);
    }

    const removePageSection = () => {
        let _pages = [...pages];
        _pages[selectedPage].sections.splice(selectedSection, 1);
        setPages(_pages);
    }

    const selectSectionHandler = (sectionId) => {
        setSelectedSection(sectionId);
        setSelectedElement(null);
    }

    const getSelectedSection = () => {
        if (selectedSection !== null) {
            return pages[selectedPage].sections[selectedSection];
        }
        return null
    }

    const updateSelectedSection = (section) => {
        if (selectedSection !== null) {
            let _pages = [...pages];
            _pages[selectedPage].sections[selectedSection] = section;
            setPages(_pages);
        }
    }

    // ********** elements **********

    const addSectionElement = (sectionIndex, columnIndex, elementIndex, elementType, position) => {
        let newElement = null;
        switch (elementType) {
            case 'heading': 
                newElement = {type: 'heading', tag: 'h1', value: 'This is your new heading', elementStyle: {}};
                break;
            case 'paragraph': 
                newElement = {type: 'paragraph', value: 'This is your new paragraph', elementStyle: {fontSize:'18', fontWeight: '400'}};
                break;
            case 'text': 
                newElement = {type: 'text', value: 'This is your new text', elementStyle: {fontSize:'18', fontWeight: '400'}};
                break;
            case 'image':
                newElement = {type: 'image', image: '', elementStyle: {}}
            default: break;
        }

        if (newElement !== null) {
            let _pages = [...pages];
            let elements = _pages[selectedPage].sections[sectionIndex].columns[columnIndex].elements;

            if (position === 'top') {
                elements.splice(elementIndex, 0, newElement);
            } else {
                elements.splice(elementIndex + 1, 0, newElement);
            }

            _pages[selectedPage].sections[sectionIndex].columns[columnIndex].elements = elements;
            setPages(_pages);
        }
    }

    const removeSelectedElement = () => {
        if (selectedElement !== null) {
            let _pages = [...pages];
            _pages[selectedPage].sections[selectedElement.section].columns[selectedElement.column].elements.splice(selectedElement.element, 1);
            setPages(_pages);
        }
    }

    const selectElementHandler = (elementId) => {
        setSelectedElement(elementId);
        setSelectedSection(null);
    }

    const getSelectedElement = () => {
        if (selectedElement !== null) {
            return pages[selectedPage].sections[selectedElement.section].columns[selectedElement.column].elements[selectedElement.element];
        }
        return null;
    }

    const updateSelectedElement = (element) => {
        if (selectedElement !== null) {
            let _pages = [...pages];
            _pages[selectedPage].sections[selectedElement.section].columns[selectedElement.column].elements[selectedElement.element] = element;
            setPages(_pages);
        }
    }

    const clearSelections = () => {
        setSelectedSection(null);
        setSelectedElement(null);
    }

    const provider = {
        name, setName,
        header, setHeader,
        footer, setFooter,
        pages, setPages,
        selectedPage, setSelectedPage,
        selectedSection, selectSection: (sectionId) => selectSectionHandler(sectionId),
        getSelectedSection,
        updateSelectedSection,
        selectedElement, selectElement: (elementId) => selectElementHandler(elementId),
        getSelectedElement,
        updateSelectedElement,
        addSectionElement,
        removeSelectedElement,
        clearSelections, 
        addPageSection,
        removePageSection,
        dragType
    }

    return <WebsiteContext.Provider value={provider}>{children}</WebsiteContext.Provider>
}

function useWebsite() {
    const context = React.useContext(WebsiteContext);
    if (!context) {
        throw new Error('useWebsite must be used within a WebsiteProvider');
    }
    return context;
}

export { WebsiteProvider, useWebsite }