import React from 'react';
import { useWebsite } from './context/website';

function Element({
    element, 
    index, 
    sectionIndex,
    columnIndex, 
    highlightedColumn, 
    setHighlightedColumn,
    highlightedElement, 
    setHighlightedElement, 
    elementHighlightPosition, 
    setElementHighlightPosition
}) {
    const { selectedElement, selectElement, addSectionElement, dragType } = useWebsite();

    const handleDragOver = (e) => {
        e.preventDefault();
        if (dragType && dragType.current !== 'section') {
            const elementRect = e.currentTarget.getBoundingClientRect();
            const mouseY = e.clientY;

            if (mouseY < elementRect.top + elementRect.height / 2) {
                setElementHighlightPosition('top');
            } else {
                setElementHighlightPosition('bottom');
            }
            setHighlightedColumn(columnIndex);
            setHighlightedElement(index);
        }
    };

    const handleDragLeave = () => {
        setHighlightedColumn(null);
        setHighlightedElement(null);
        setElementHighlightPosition(null);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const type = e.dataTransfer.getData('type');
        if (type !== 'section') {
            addSectionElement(sectionIndex, columnIndex, index, type, elementHighlightPosition);
            // Handle the drop logic here
            setHighlightedColumn(null);
            setHighlightedElement(null);
            setElementHighlightPosition(null);
        }
    };

    const handleClick = (evt) => {
        evt.stopPropagation();
        selectElement({section: sectionIndex, column: columnIndex, element: index});
    }

    return (
        <div 
            className={'mt-element' + (selectedElement && (selectedElement.section === sectionIndex && selectedElement.column === columnIndex && selectedElement.element === index) ? ' selected' : '')} 
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClick}
            style={{
                ...element.elementStyle,
                borderTop: highlightedElement === index && highlightedColumn === columnIndex && elementHighlightPosition === 'top' ? '2px solid #2599C4' : 'none',
                borderBottom: highlightedElement === index && highlightedColumn === columnIndex && elementHighlightPosition === 'bottom' ? '2px solid #2599C4' : 'none',
            }}
        >
            {highlightedElement === index && highlightedColumn === columnIndex && elementHighlightPosition && (
                <div
                    className={`drop-bubble ${elementHighlightPosition === 'top' ? 'drop-bubble-top' : 'drop-bubble-bottom'}`}
                >
                    Drop to insert here
                </div>
            )}
            {element.type === 'heading' && 
                (element.tag === 'h1' ? <h1 >{element.value}</h1>
                    : element.tag === 'h2' ? <h2 >{element.value}</h2>
                    : element.tag === 'h3' ? <h3 >{element.value}</h3>
                    : element.tag === 'h4' ? <h4 >{element.value}</h4>
                    : element.tag === 'h5' ? <h5 >{element.value}</h5>
                    : element.tag === 'h6' && <h6 >{element.value}</h6>
                )
            }
            {element.type === 'paragraph' && <p >{element.value}</p>}
            {element.type === 'text' && <div  dangerouslySetInnerHTML={{__html: element.value}}></div>}
            {element.type === 'image' && <div className='mt-image-container' style={{backgroundImage:`url('${element.image}')`}} />}
        </div>
    )
}

export default Element;