import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function IconButton({icon, label, onClick, iconSize='14px'}) {

    return (
        <div className='icon-button' role='button' onClick={onClick} tabIndex={0}>
            <div className='icon-button-contents'>
                <div className='icon-container'><FontAwesomeIcon icon={icon} style={{fontSize: iconSize}}/></div>
                <div className='label-container'>{label}</div>
            </div>
        </div>
    )
}

export default IconButton;