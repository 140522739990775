import { useEvent } from 'contexts/event';
import moment from 'moment';
import React, { useState } from 'react';

function Search() {
    const { findCustomer } = useEvent();
    const [email, setEmail] = useState('');
    const [results, setResults] = useState(null);

    const searchHandler = () => {
        findCustomer(email, response => {
            setResults(response);
        })
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            searchHandler();
        }
    };

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Find Customer (BETA)</div>
                </div>
            </div>
            <div style={{display:'flex',flexFlow:'column',maxWidth:'400px'}}>
                <p>Please provide feedback to support@middletwin.com</p>
                <p>Enter an email address to find registrations, tickets, and vendors with the email address.</p>
                <label>Customer email address</label>
                <input type='email' value={email} onChange={e => setEmail(e.target.value)} onKeyDown={handleKeyDown} />
                <div className='buttonsHolder'>
                    <button onClick={searchHandler} disabled={email.length === 0}>Search</button>
                </div>
            </div>
            {results &&
                <>
                    {results.entries.length === 0
                        ? <div>This customer has not registered any cars.</div>
                        : 
                        <div>
                            {results.entries.length} registered cars
                            <table style={{borderCollapse:'collapse',width:'100%'}}><tbody className='resultsTable'>
                                <tr><th>Car Number</th><th>Class</th><th>Year</th><th>Make</th><th>Model</th></tr>
                                {results.entries.map(ent => 
                                    <tr key={ent.id}><td>{ent.car_number}</td><td>{ent.car_class}</td><td>{ent.year}</td><td>{ent.make}</td><td>{ent.model}</td></tr>)}
                            </tbody></table>
                        </div>
                    }
                    <hr/>
                    {results.tickets.length === 0
                        ? <div>This customer has not purchased any tickets.</div>
                        : 
                        <div>
                            {results.tickets.length} tickets purchased
                            <table style={{borderCollapse:'collapse',width:'100%'}}><tbody className='resultsTable'>
                                <tr><th>Date</th><th>Name</th></tr>
                                {results.tickets.map(tic => 
                                    <tr key={tic.id}><td>{moment(tic.purchased).format('ddd, MMM Do, YYYY')}</td><td>{tic.name}</td></tr>)}
                            </tbody></table>
                        </div>
                    }
                    <hr/>
                    {results.vendors.length === 0
                        ? <div>This customer has not registered as a vendor.</div>
                        : 
                        <div>
                            {results.vendors.length} vendor registrations
                            <table style={{borderCollapse:'collapse',width:'100%'}}><tbody className='resultsTable'>
                                <tr><th>Name</th><th>Spaces</th></tr>
                                {results.vendors.map(vend => 
                                    <tr key={vend.id}><td>{vend.name}</td><td>{
                                        vend.elements.map((elem, idx) => <span key={elem.id}>{`${elem.row}-${elem.num}${idx < vend.elements.length -1 ? ', ' : ''}`}</span>)}</td></tr>)}
                            </tbody></table>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default Search;