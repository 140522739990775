import React, { useEffect, useState } from 'react';
import { ColorSetting, SliderSetting } from './controls';
import { useWebsite } from '../context/website';

function StyleSettings() {
    const { getSelectedElement, updateSelectedElement } = useWebsite();
    const [element, setElement] = useState(getSelectedElement());
    const [elementWidth, setElementWidth] = useState(element.elementStyle?.elementWidth ? parseInt(element.elementStyle.elementWidth.replace('%', '')) : 100);
    const [elementHeight, setElementHeight] = useState(element.elementStyle?.elementHeight ? parseInt(element.elementStyle.elementHeight.replace('%', '')) : 100);
    const [color, setColor] = useState(element.elementStyle?.color ? element.elementStyle.color : '#000000');
    const [backgroundColor, setBackgroundColor] = useState(element.elementStyle?.backgroundColor ? element.elementStyle.backgroundColor : '#FFFFFF');
    
    useEffect(() => {
        updateSelectedElement(element);
    }, [element])

    useEffect(() => {
        setElement({...element, elementStyle: {
            ...element.elementStyle,
            //width: elementWidth + '%', 
            //height: elementHeight + '%', 
            color,
            backgroundColor
        }});
    }, [elementWidth, elementHeight, backgroundColor])

    return (
        <div>
            {false &&
            <>
            <label>Width</label>
            <SliderSetting value={elementWidth} onChange={setElementWidth} />
            <label>Height</label>
            <SliderSetting value={elementHeight} onChange={setElementHeight} />
            </>
            }
            <label>Color</label>
            <ColorSetting value={color} onChange={setColor} />
            <label>Background color</label>
            <ColorSetting value={backgroundColor} onChange={setBackgroundColor} />
        </div>
    )
}

export default StyleSettings;