import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPencil, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import React, { useRef, useState } from 'react';
import SidePanel from 'components/sidePanel';
import 'style/schedule.scss';
import { useSchedule } from 'contexts/eventSchedule';

function Schedule() {
    const schedule = useSchedule();
    const dayPanel = useRef();
    const itemPanel = useRef();
    const [dayTitle, setDayTitle] = useState('');
    const [editingDay, setEditingDay] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [label, setLabel] = useState('');
    const [editingItem, setEditingItem] = useState(null);

    const editDay = (day) => {
        setDayTitle(day.title);
        setEditingDay(day);
        dayPanel.current.show();
    }

    const saveDay = () => {
        if (editingDay) {
            const day = {...editingDay, title: dayTitle};
            schedule.updateDay(day);
        } else {
            const day = {title: dayTitle, data: []};
            schedule.addDay(day);
        }
        onCloseDayPanel();
    }

    const onCloseDayPanel = () => {
        setDayTitle('');
        setEditingDay(null);
        dayPanel.current.hide();
    }

    const removeDay = (day) => {
        schedule.removeDay(day);
    }
    // item
    const addItem = (day) => {
        setEditingDay(day);
        itemPanel.current.show();
    }

    const editItem = (day, item) => {
        setEditingDay(day);
        setStartTime(item.startTime);
        setEndTime(item.endTime);
        setLabel(item.label);
        setEditingItem(item);
        itemPanel.current.show();
    }

    const saveItem = () => {
        if (editingItem) {
            const item = {...editingItem, startTime, endTime, label};
            schedule.updateItem(editingDay, item);
        } else {
            const item = {startTime, endTime, label};
            schedule.addItem(editingDay, item);
        }
        onCloseItemPanel();
    }

    const onCloseItemPanel = () => {
        setEditingDay(null);
        setStartTime('');
        setEndTime('');
        setLabel('');
        setEditingItem(null);
        itemPanel.current.hide();
    }

    const removeItem = (day, item) => {
        schedule.removeItem(day, item);
    }

    const moveItem = (day, item, direction) => {
        schedule.moveItem(day, item, direction);
    }

    return (
        <>
            <p>
                Click <button onClick={() => dayPanel.current.show()}>Add Day</button> to add a new day to the schedule. 
                Use the buttons within the row of the day label to manage the day. Use the pencil to edit a day label,
                select the trash bin to delete the day and all its items, and use the plus to add an item to the day. 
                Use the buttons within the row of the item label to manage the item. Use the pencil to edit a label or
                start and end times, and the trash bin to remove the item.<br/><br/>
                Note that changes are not saved until you save the event using the button at the bottom of all the event details.
            </p>
            <div className='eventSchedule'>
                {schedule && 
                    schedule.days.map((day, dayIndex) =>
                        <div key={day.id}> 
                            <div className='day'>
                                {day.title}
                                <div className='actionButtons'>
                                    <button onClick={() => editDay(day)}><FontAwesomeIcon icon={faPencil} /></button>
                                    <button onClick={() => removeDay(day)}><FontAwesomeIcon icon={faTrash} /></button>
                                    <button onClick={() => addItem(day)}><FontAwesomeIcon icon={faPlus} /></button>
                                </div>
                            </div>
                            {day.data.map((item, itemIndex) => 
                                <div key={item.id}>
                                    <div className='item'>
                                        {`${item.startTime} - ${item.endTime} ${item.label}`}
                                        <div className='actionButtons'>
                                            {itemIndex > 0 && 
                                                <button onClick={() => moveItem(dayIndex, itemIndex, 'up')}><FontAwesomeIcon icon={faChevronUp} /></button>
                                            }
                                            {itemIndex < day.data.length-1 && 
                                                <button onClick={() => moveItem(dayIndex, itemIndex, 'down')}><FontAwesomeIcon icon={faChevronDown} /></button>
                                            }
                                            <button onClick={() => editItem(day, item)}><FontAwesomeIcon icon={faPencil} /></button>
                                            <button onClick={() => removeItem(day, item)}><FontAwesomeIcon icon={faTrash} /></button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                }
            </div>
            <SidePanel title='Day' ref={dayPanel}>
                <div>
                    <label>Day Title</label><br/>
                    <input type='text' value={dayTitle} onChange={e => setDayTitle(e.target.value)} maxLength={32} />
                </div>
                <button onClick={onCloseDayPanel}>Cancel</button>
                <button onClick={saveDay}>{editingDay ? 'Modify' : 'Add'}</button>
            </SidePanel>
            <SidePanel title='Schedule Item' ref={itemPanel}>
                <div>
                    <label>Start Time</label><br/>
                    <input type='text' value={startTime} onChange={e => setStartTime(e.target.value)} maxLength={24} />
                </div>
                <div>
                    <label>End Time</label><br/>
                    <input type='text' value={endTime} onChange={e => setEndTime(e.target.value)} maxLength={24} />
                </div>
                <div>
                    <label>Label</label><br/>
                    <input type='text' value={label} onChange={e => setLabel(e.target.value)} maxLength={32} />
                </div>
                <button onClick={onCloseItemPanel}>Cancel</button>
                <button onClick={saveItem}>{editingItem ? 'Modify' : 'Add'}</button>
            </SidePanel>
        </>
    )
}

const style = {
    day: {
        backgroundColor: '#ddd'
    }
}

export default Schedule;