import React, { useState } from 'react';
import { useWebsite } from './context/website';
import Element from './element';

function Section({section, index}) {
    const { addPageSection, 
        selectedSection, selectSection, dragType } = useWebsite();
    const [highlightedSection, setHighlightedSection] = useState(null);
    const [highlightPosition, setHighlightPosition] = useState(null);
    const [highlightedColumn, setHighlightedColumn] = useState(null);;
    const [highlightedElement, setHighlightedElement] = useState(null);
    const [elementHighlightPosition, setElementHighlightPosition] = useState(null);

    const handleDragOver = (e, sidx) => {
        e.preventDefault();
        if (dragType && dragType.current === 'section') {
            const sectionElement = e.currentTarget;
            const sectionRect = sectionElement.getBoundingClientRect();
            const mouseY = e.clientY;

            if (mouseY < sectionRect.top + sectionRect.height / 2) {
                setHighlightPosition('top');
            } else {
                setHighlightPosition('bottom');
            }
            setHighlightedSection(sidx);
        }
    };

    const handleDragLeave = () => {
        setHighlightedSection(null);
        setHighlightPosition(null);
        setHighlightedColumn(null);
        setHighlightedElement(null);
        setElementHighlightPosition(null);
    };

    const handleDrop = (e, sidx) => {
        e.preventDefault();
        const type = e.dataTransfer.getData('type');
        if (type === 'section') {
            addPageSection(sidx, highlightPosition);
            setHighlightedSection(null);
            setHighlightPosition(null);
        }
    };

    return (
        <div 
            className={'mt-section' + (selectedSection === index ? ' selected' : '')}
            onClick={() => selectSection(index)}
            style={{
                ...section.sectionStyle,
                boxShadow: highlightedSection === index && highlightPosition === 'bottom' ? '0 -2px 0 0 #2599C4 inset' : 
                            highlightedSection === index && highlightPosition === 'top' ? '0 2px 0 0 #2599C4 inset' : 'none',
            }}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, index)}
        >
            {section.columns.map((column, columnIndex) => (
                <div key={`column-${columnIndex}`} className='mt-column' onClick={() => selectSection(index)}>
                    {column.elements.map((element, elementIndex) => (
                        <Element 
                            key={`element-${elementIndex}`} 
                            element={element} 
                            index={elementIndex} 
                            sectionIndex={index}
                            columnIndex={columnIndex}
                            highlightedColumn={highlightedColumn}
                            setHighlightedColumn={setHighlightedColumn}
                            highlightedElement={highlightedElement}
                            setHighlightedElement={setHighlightedElement}
                            elementHighlightPosition={elementHighlightPosition}
                            setElementHighlightPosition={setElementHighlightPosition} />
                    ))}
                </div>
            ))}
        </div>
    )
}

export default Section;