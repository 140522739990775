import { useEventCustomers } from "contexts/eventCustomers";
import React, { useEffect, useState } from "react";
import './customers.scss';
import { useTicket } from "contexts/ticket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/pro-regular-svg-icons";
import { useEvent } from "contexts/event";
import SidePanel from "components/sidePanel";
import { useClasses } from "contexts/classes";
import { useRegistrations } from "contexts/registrations";
import { EventRegistrationOptionsProvider } from "contexts/eventRegistrationOptions";
import RegistrationDetails from "../registration/registrationDetails";
import Modal from "components/modal";
import { useTicketRedemption } from "contexts/ticketRedemption";
import VendorDetails from "../maps/vendorDetails";
import { VendorsProvider } from "contexts/vendors";
import moment from "moment";

function Customers() {
    const { customers } = useEventCustomers();
    const { tickets } = useTicket();
    const { event } = useEvent();
    const { classes } = useClasses();
    const { saveRegistration } = useRegistrations();
    const { redeemTickets } = useTicketRedemption();
    const [customer, setCustomer] = useState(null);
    const [order, setOrder] = useState('fname');
    const [filter, setFilter] = useState('all');
    const [search, setSearch] = useState('');
    const [sortedCustomers, setSortedCustomers] = useState([]);
    const regDetailsPanel = React.createRef();
    const [showingRegistrationDetails, setShowingRegistrationDetails] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [ticketToRedeem, setTicketToRedeem] = useState(null);
    const vendorDetailsPanel = React.createRef();
    const [vendor, setVendor] = useState(null);

    useEffect(() => {
        const sorted = [...customers].sort((a, b) => {
            if (order === 'fname') {
                return a.firstName.localeCompare(b.firstName);
            } else if (order === 'lname') {
                return a.lastName.localeCompare(b.lastName);
            } else if (order === 'email') {
                return a.email.localeCompare(b.email);
            }
            return 0;
        });
        setSortedCustomers(sorted);
    }, [order, customers])

    const showRegistration = (entry) => {
        setRegistration(entry);
        regDetailsPanel.current.show();
        setShowingRegistrationDetails(true);
    }

    const hideRegistrationDetailsPanel = () => {
        setRegistration(null);
        setShowingRegistrationDetails(false);
    }

    const saveRegistrationHandler = (data) => {
        regDetailsPanel.current.hide();
        setShowingRegistrationDetails(false);
        if (registration) {
            saveRegistration(data, false, () => {
                // Update the specific entry with the data
                updateCustomerEntry(customer.email, data.car_number, {
                    processed: data.processed,
                    notes: data.notes
                });
                setCustomer(prevCustomer => {
                    if (prevCustomer) {
                        const updatedEntries = prevCustomer.entries.map(ntry => {
                            if (data.car_number === ntry.car_number) {
                                return { ...ntry, processed: data.processed, notes: data.notes }; // Merge updated data into the entry
                            }
                            return ntry;
                        })
                        return {...prevCustomer, entries: updatedEntries};
                    }
                    return prevCustomer;
                });
            });
        }
    }

    const updateCustomerEntry = (customerEmail, carNumber, updatedEntryData) => {
        setSortedCustomers(prevCustomers => {
            return prevCustomers.map(customer => {
                if (customer.entries.length > 0 && customer.email === customerEmail) {
                    // Update the specific entry in the customer's entries array
                    const updatedEntries = customer.entries.map(entry => {
                        if (entry.car_number === carNumber) {
                            return { ...entry, ...updatedEntryData }; // Merge updated data into the entry
                        }
                        return entry;
                    });
    
                    // Return a new customer object with updated entries
                    return { ...customer, entries: updatedEntries };
                }
                return customer;
            });
        });
    };

    const redeemTicketHandler = () => {
        const data = [ticketToRedeem.id];
        redeemTickets(data, () => {
            setSortedCustomers(prevCustomers => {
                const updatedCustomers = prevCustomers.map(cust => {
                    if (cust.tickets.length > 0 && customer.email === cust.email) {
                        // update the ticket redemption date
                        const updatedTickets = cust.tickets.map(tkt => {
                            if (tkt.id === ticketToRedeem.id) {
                                return {...tkt, redeemed: moment().valueOf()};
                            }
                            return tkt;
                        });
                        return {...cust, tickets: updatedTickets};
                    }
                    return cust;
                });
                return updatedCustomers;
            });
            setCustomer(prevCustomer => {
                if (prevCustomer) {
                    const updatedTickets = prevCustomer.tickets.map(tkt => {
                        if (tkt.id === ticketToRedeem.id) {
                            return { ...tkt, redeemed: moment().valueOf() };
                        }
                        return tkt;
                    });
                    return { ...prevCustomer, tickets: updatedTickets };
                }
                return prevCustomer;
            });
            setTicketToRedeem(null);
        });
    }

    const showVendor = (vndr) => {
        setVendor(vndr);
        vendorDetailsPanel.current.show();
    }

    const hideVendorDetailsPanel = () => {
        setVendor(null);
    }

    const saveVendorHandler = (vendorData) => {
        vendorDetailsPanel.current.hide();
        setSortedCustomers(prevCustomers => {
            return prevCustomers.map(cust => {
                if (cust.vendors.length > 0 && customer.email === cust.email) {
                    // update the vendor
                    const updatedVendors = cust.vendors.map(vndr => {
                        if (vendor.id === vndr.id) {
                            return { ...vndr, processed: vendorData.processed };
                        }
                        return vndr;
                    })
                    return { ...cust, vendors: updatedVendors };
                }
                return cust;
            })
        });
        // Explicitly update the customer state
        setCustomer(prevCustomer => {
            if (prevCustomer) {
                const updatedVendors = prevCustomer.vendors.map(vndr => {
                    if (vndr.id === vendor.id) {
                        return { ...vndr, processed: vendorData.processed };
                    }
                    return vndr;
                });
                return { ...prevCustomer, vendors: updatedVendors };
            }
            return prevCustomer;
        });
        setVendor(null);
    }

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Customers</div>
                </div>
            </div>
            <div className='customers-page'>
                <p>From this view you can see all the customers for the show and you can see everything they have purchased
                    (registrations, tickets, and vendor/swap spaces) all in one view. Additionally you can click on a customer row
                    to show all the purchases and you can click on a registration row to edit that registration, you can click 
                    on a ticket to redeem that ticket, and you can click on a vendor/swap row to edit the details of that 
                    vendor of swapper.</p>
                <div className='customers-container'>
                    <div className='customers-controls'>
                        <div className='customer-control'>
                            <label>Sort</label>
                            <select value={order} onChange={evt => setOrder(evt.target.value)}>
                                <option value='fname'>First Name</option>
                                <option value='lname'>Last Name</option>
                                <option value='email'>Email</option>
                            </select>
                        </div>
                        <div className='customer-control'>
                            <label>Filter</label>
                            <select value={filter} onChange={evt => setFilter(evt.target.value)}>
                                <option value='all'>All</option>
                                <option value='registrations'>Registrations</option>
                                <option value='tickets'>Tickets</option>
                                <option value='vendor'>Vendor/Swapper</option>
                            </select>
                        </div>
                        <div className='customer-control'>
                            <label>Search (at least 3 charaters)</label>
                            <input type='text' style={{margin:'3px'}} value={search} onChange={evt => setSearch(evt.target.value)} maxLength={32} />
                        </div>
                    </div>

                    <table className='customers-table' cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr><th>First Name</th><th>Last Name</th><th>Email</th><th>Registrations</th><th>Tickets</th><th>Vendor Spaces</th></tr>
                        </thead>
                        <tbody>
                            {sortedCustomers.map((cust, idx) => {
                                // use filter
                                if (filter !== 'all') {
                                    if (
                                        (filter === 'registrations' && cust.entries.length === 0) ||
                                        (filter === 'tickets' && cust.tickets.length === 0) ||
                                        (filter === 'vendor' && cust.vendors.length === 0)
                                    ) {
                                        return null;
                                    }
                                }
                                // use search
                                if (search.length > 2) {
                                    let isMatch = false;
                                    const searchTerm = search.trim().toLowerCase();
                                    if (cust.firstName.trim().toLowerCase().startsWith(searchTerm)) {
                                        isMatch = true;
                                    }
                                    if (!isMatch && cust.lastName.trim().toLowerCase().startsWith(searchTerm)) {
                                        isMatch = true;
                                    }
                                    if (!isMatch && cust.email.trim().toLowerCase().includes(searchTerm)) {
                                        isMatch = true;
                                    }
                                    if (!isMatch) {
                                        return null;
                                    }
                                }
                                const processedRegistrations = cust.entries.every(entry => entry.processed);
                                const ticketsRedeemed = cust.tickets.every(ticket => ticket.redeemed);
                                const processedVendors = cust.vendors.every(vendor => vendor.processed);
                                const numSpaces = cust.vendors.reduce((acc, vendor) => acc + vendor.elements.length, 0);
                                return (
                                    <> 
                                        <tr className='customer-row' key={idx} onClick={() => setCustomer(cust)}>
                                            <td>{cust.firstName}</td>
                                            <td>{cust.lastName}</td>
                                            <td>{cust.email}</td>
                                            {cust.entries.length === 0 ? <td>None</td> :
                                                <td>Registrations: {cust.entries.length}, Processed: <FontAwesomeIcon icon={processedRegistrations ? faThumbsUp : faThumbsDown} style={{color: processedRegistrations ? 'green' : 'red'}}/></td>
                                            }
                                            {cust.tickets.length === 0 ? <td>None</td> :
                                                <td>Tickets: {cust.tickets.length}, Redeemed: <FontAwesomeIcon icon={ticketsRedeemed ? faThumbsUp : faThumbsDown} style={{color: ticketsRedeemed ? 'green' : 'red'}}/></td>
                                            }
                                            {cust.vendors.length === 0 ? <td>None</td> :
                                                <td>Spaces: {numSpaces}, Processed: <FontAwesomeIcon icon={processedVendors ? faThumbsUp : faThumbsDown} style={{color: processedVendors ? 'green' : 'red'}}/></td>
                                            }
                                        </tr>
                                        {customer && customer.email === cust.email &&
                                            <tr className='customer-details-row'>
                                                <td colSpan={6}>
                                                    <table className='customer-details-table' cellPadding={0} cellSpacing={0}>
                                                        <thead>
                                                            <tr><th>Purchase Type</th><th>Details</th></tr>
                                                        </thead>
                                                        <tbody>
                                                            {customer.entries.map((ntry, ntryIdx) => {
                                                                return (<tr key={ntryIdx} onClick={() => showRegistration(ntry)}> <td>Registration</td>
                                                                    <td>Processed: <FontAwesomeIcon icon={ntry.processed ? faThumbsUp : faThumbsDown}  style={{color: ntry.processed ? 'green' : 'red'}}/>, Class: {ntry.car_class}, Number: {ntry.car_number}</td>
                                                                </tr>);
                                                            })}
                                                            {customer.tickets.map((tkt, tktIdx) => {
                                                                const ticket = tickets.find(t => t.id === tkt.eventticket);
                                                                return (<tr key={tktIdx} onClick={() => setTicketToRedeem(tkt)}><td>Ticket</td>
                                                                    <td>Redeemed: <FontAwesomeIcon icon={tkt.redeemed ? faThumbsUp : faThumbsDown}  style={{color: tkt.redeemed ? 'green' : 'red'}}/>, {`Type: ${ticket ? ticket.description : 'Unknown'}`}</td>
                                                                </tr>);
                                                            })}
                                                            {customer.vendors.map((vndr, vndrIdx) => {
                                                                const spaces = vndr.elements.map(elem => `${elem.row}-${elem.num}`).join(',');
                                                                return (<tr key={vndrIdx} onClick={() => showVendor(vndr)}><td>Vendor/Swap</td>
                                                                    <td>Processed: <FontAwesomeIcon icon={vndr.processed ? faThumbsUp : faThumbsDown}  style={{color: vndr.processed ? 'green' : 'red'}}/>, Spaces: {spaces}</td>
                                                                </tr>);
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <SidePanel title='Registration' ref={regDetailsPanel} onHide={hideRegistrationDetailsPanel}>
                {showingRegistrationDetails && 
                    <EventRegistrationOptionsProvider eventId={event.id}>
                        <RegistrationDetails 
                            event={event}
                            classes={classes} 
                            registration={registration} 
                            onSave={saveRegistrationHandler}
                        />
                    </EventRegistrationOptionsProvider>
                }
            </SidePanel>
            <SidePanel title='Vendor' ref={vendorDetailsPanel} onHide={hideVendorDetailsPanel}>
                <VendorsProvider eventId={event.id} dontLoadData={true}>
                    <VendorDetails vendor={vendor} onSave={saveVendorHandler} onCancel={hideVendorDetailsPanel} />
                </VendorsProvider>
            </SidePanel>
            <Modal title='Redeem Ticket' 
                showing={ticketToRedeem}
                onHide={() => setTicketToRedeem(null)}
                        buttons={[
                            {type: 'primary', handler:() => redeemTicketHandler(), label: 'Redeem'},
                            {type: 'secondary', handler:() => setTicketToRedeem(null), label: 'Cancel'}
                        ]}>
                <div style={{width:'400px'}}>
                    {ticketToRedeem && 
                        <p>You are about to redeem a {tickets.find(t => t.id === ticketToRedeem.eventticket).description} ticket. 
                            This cannot be undone. Click the Redeem button to proceed.</p>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Customers;