import React, { useState } from 'react';

const WebsitesContext = React.createContext(null);

function WebsitesProvider({children}) {
    const [loadingWebsites, setLoadingWebsites] = useState(false);
    const [websites, setWebsites] = useState([]);

    const loadWebsites = () => {

    }

    const provider = {
        loadingWebsites,
        websites,
        loadWebsites,
    }

    return <WebsitesContext.Provider value={provider}>{children}</WebsitesContext.Provider>
}

function useWebsites() {
    const context = React.useContext(WebsitesContext);
    if (!context) {
        throw new Error('useWebsites must be used within a WebsitesProvider');
    }
    return context;
}

export { WebsitesProvider, useWebsites }