import React, { useEffect, useState } from 'react';
import { useWebsite } from '../context/website';

function HeadingSettings({elementId}) {
    const { getSelectedElement, updateSelectedElement } = useWebsite();
    const [element, setElement] = useState(getSelectedElement());
    const [value, setValue] = useState(element.value);
    const [tag, setTag] = useState(element.tag);
    const [textAlign, setTextAlign] = useState('left');

    useEffect(() => {
        console.log('elementId ', elementId)
        const newElement = getSelectedElement();
        setValue(newElement.value);
        setTag(newElement.tag);
        if (newElement.elementStyle) {
            setTextAlign(newElement.elementStyle.textAlign || 'left');
        }
        setElement(newElement);
    }, [elementId])

    useEffect(() => {
        updateSelectedElement(element);
    }, [element])

    useEffect(() => {
        setElement({...element, value});
    }, [value])

    useEffect(() => {
        setElement({...element, tag})
    }, [tag])

    useEffect(() => {
        setElement({...element, elementStyle: {textAlign}});
    }, [textAlign])

    return (
        <>
            <label>Heading text</label>
            <input type='text' value={value} onChange={(evt) => setValue(evt.target.value)} maxLength={1024} />
            <label>Type</label>
            <select value={tag} onChange={(evt) => setTag(evt.target.value)}>
                <option value='h1'>Heading 1</option>
                <option value='h2'>Heading 2</option>
                <option value='h3'>Heading 3</option>
                <option value='h4'>Heading 4</option>
                <option value='h5'>Heading 5</option>
                <option value='h6'>Heading 6</option>
            </select>
            <label>Text alignment</label>
            <select value={textAlign} onChange={evt => setTextAlign(evt.target.value)}>
                <option value='left'>Left</option>
                <option value='center'>Center</option>
                <option value='right'>Right</option>
            </select>
        </>
    )
}

export default HeadingSettings;