import HiddenCanvas from 'components/hiddenCanvas';
import React, { useEffect, useState } from 'react';
import { useWebsite } from '../context/website';

function ImageSettings({elementId}) {
    const { getSelectedElement, updateSelectedElement } = useWebsite();
    const [element, setElement] = useState(getSelectedElement());
    const [image, setImage] = useState(element.iamge);

    useEffect(() => {
        console.log('elementId ', elementId)
        const newElement = getSelectedElement();
        setImage(newElement.image);
        setElement(newElement);
    }, [elementId])

    useEffect(() => {
        updateSelectedElement(element);
    }, [element])

    useEffect(() => {
        setElement({...element, image});
    }, [image])

    const chooseImageHandler = (imageData) => {
        setElement({...element, image: imageData});
    }

    return (
        <>
            <label>Image</label>
            <HiddenCanvas buttonTitle='Choose image' width={400} height={400} onChange={chooseImageHandler}/>
        </>
    )
}

export default ImageSettings;