import React, { useState } from 'react';
import AmazingTable from 'components/amazingTable';
import SidePanel from 'components/sidePanel';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useVendors } from 'contexts/vendors';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import VendorDetails from './vendorDetails';

function Vendors({eventId}) {
    const navigate = useNavigate();
    const auth = useAuth();
    const { getVendors, vendors, deleteVendors } = useVendors();
    const [editingVendor, setEditingVendor] = useState(null);
    const vendorPanel = React.useRef();

    const createVendorPanel = () => {
        setEditingVendor(null);
        vendorPanel.current.show();
    }

    const selectVendor = (row) => {
        setEditingVendor(row);
        vendorPanel.current.show();
    }

    const onCancelHandler = () => {
        setEditingVendor(null);
        vendorPanel.current.hide();
    }

    const deleteVendorsHandler = (rows) => {
        const rowIds = rows.map((r) => r.id);
        deleteVendors(rowIds);
    }

    const handleExport = () => {
        var csvData = [["name", "email", "phone", "address", "city", 'state', 'zip', 'taxid']];
        vendors.forEach(v => csvData.push([v.name, v.email, v.phone, v.address, v.city, v.state, v.zip, v.taxId]));

        let csvContent = "data:text/csv;charset=utf-8," 
            + csvData.map(e => e.join(",")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const getVendorTypeLabel = (typeId) => {
        let typeLabel = '';
        switch (typeId) {
            case 0: typeLabel = 'Unknown'; break;
            case 1: typeLabel = 'Swapper'; break;
            case 2: typeLabel = 'Food'; break;
            case 3: typeLabel = 'Merchandise'; break;
        }
        return typeLabel;
    }

    const columns = [
        {id: 'id', label: 'ID', display: false, datum: 'id'},
        {id: 'eventId', label: 'Event ID', display: false, datum: 'eventid'},
        {id: 'type', label: 'Type', display: true, style: {width:'100px'}, formatter: obj => getVendorTypeLabel(obj.vendorType)},
        {id: 'name', label: 'Name', display: true, datum: 'name'},
        {id: 'phone', label: 'Phone', display: true, style: {width:'100px'}, datum: 'phone'},
        {id: 'email', label: 'Email', display: true, datum: 'email'},
        {id: 'address', label: 'Address', display: true, style: {width:'150px'}, datum: 'address'},
        {id: 'city', label: 'City', display: true, style: {width:'100px'}, datum: 'city'},
        {id: 'state', label: 'State', display: true, style: {width:'100px'}, datum: 'state'},
        {id: 'zip', label: 'Zip', display: false, style: {width:'100px'}, datum: 'zip'},
        {id: 'taxid', label: 'Tax ID', display: false, style: {width:'100px'}, datum: 'taxid'},
        {id: 'processed', label: 'Processed', display: true, style:{width:'100px'}, formatter: (obj) => {
            return <FontAwesomeIcon icon={obj.processed ? faThumbsUp : faThumbsDown} style={{color: obj.processed ? 'green' : 'red'}}/>}},
        {id: 'spaces', label: 'Spaces', display: true, formatter: obj => obj.elements.map(el => `${el.row}-${el.num}`).join(',')}
    ];

    const labelHandler = () => {
        navigate(`/labels/vendors/${auth.tenant.uuid}/${eventId}`);
    }

    return (
        <>
            <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>{`${vendors.length} Total / ${vendors.filter(vdr => vdr.processed).length} Processed`}</div>
            <TableSetupProvider tableName='vendors' defaultColumns={columns}>
                <AmazingTable 
                    rows={vendors}
                    onRefresh={getVendors}
                    onExport={handleExport}
                    onRowClick={selectVendor}
                    onRowSelect={() => {}}
                    onCreate={createVendorPanel}
                    onCreateLabel='Add vendor'
                    onDelete={deleteVendorsHandler}
                    onLabel={labelHandler} />
            </TableSetupProvider>
            <SidePanel title='Vendor' ref={vendorPanel}>
                <VendorDetails vendor={editingVendor} onSave={onCancelHandler} onCancel={onCancelHandler} />
            </SidePanel>
        </>
    )
}

export default Vendors;