import { faCar, faFillDrip, faHeading, faHorizontalRule, faImage, faParagraph, faPencil, faQrcode, faRectangleLandscape, faSquare, faText, faThLarge, faTools, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWebsite } from './context/website';
import React, { useEffect, useState } from 'react';
import './websiteDesignStudio.scss';
import logo from 'assets/logo-white.png';
import IconButton from 'components/iconButton';
import TextSettings from './settings/textSettings';
import Section from './section';
import HeadingSettings from './settings/headingSettings';
import ParagraphSettings from './settings/paragraphSettings';
import SectionSettings from './settings/sectionSettings';
import CommonSettings from './settings/commonSettings';
import StyleSettings from './settings/styleSettings';
import ImageSettings from './settings/imageSettings';

function WebsiteDesignStudio() {
    const { name, setName, 
        header, setHeader, 
        footer, setFooter, 
        pages, setPages, 
        selectedPage, setSelectedPage,
        selectedSection, selectedElement,
        getSelectedSection, getSelectedElement, removeSelectedElement,
        clearSelections,
        removePageSection,
        dragType } = useWebsite();
    const [settingsTab, setSettingsTab] = useState('content');

    const handleDragStart = (e, type) => {
        clearSelections();
        e.dataTransfer.setData('type', type);
        dragType.current = type;
    };

    const changePageHandler = (pageIndex) => {
        clearSelections();
        setSelectedPage(pageIndex);
    }

    const deSelectBoxes = () => {
        clearSelections();
    }

    const removeSectionHandler = () => {
        removePageSection();
        clearSelections();
    }

    const removeElementHandler = () => {
        removeSelectedElement();
        clearSelections();
    }

    return (
        <div className='website-design-studio'>
            <div className='website-settings-area'>
                <div className='website-name-container'>
                    <label>Name</label>
                    <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64' />
                </div>
            </div>
            <div className='website-editor-container'>
                <div className='website-preview-area'>
                    <div className='website-preview'>
                        <div className='website-webpage-area'>
                            <div className='website-header' style={{backgroundColor:header.backgroundColor, color:header.color}}>
                                <ul>
                                    {pages.map((p, idx) => 
                                        <li key={idx} 
                                            className={selectedPage === idx ? 'selected' : ''}
                                            onClick={() => changePageHandler(idx)}>{p.label}</li>)}
                                </ul>
                            </div>
                            <div className='website-page'>
                                    <Page page={pages[selectedPage]} />
                            </div>
                            <div className='website-footer' style={{backgroundColor:footer.backgroundColor, color:footer.color}}>
                                Created using <img src={logo} alt='Middletwin' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='website-tools-area'>
                    {selectedSection !== null ?
                        <div className='selection-details'>
                            <div className='selection-actions'>
                                <IconButton icon={faThLarge} label='Tiles' onClick={deSelectBoxes}/>
                                <IconButton icon={faTrash} label='Remove' onClick={removeSectionHandler}/>
                            </div>
                            <div className='module-settings-tabs'>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'content' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('content')}>
                                    <IconButton icon={faPencil} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Content</div>
                                </div>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'style' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('style')}>
                                    <IconButton icon={faFillDrip} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Style</div>
                                </div>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'advanced' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('advanced')}>
                                    <IconButton icon={faTools} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Advanced</div>
                                </div>
                            </div>
                            {settingsTab === 'content' && <SectionSettings />}
                            {settingsTab === 'style' && <StyleSettings element={{settings: {}}}/>}
                            {settingsTab === 'advanced' && <CommonSettings element={{settings: {}}} />}
                        </div>
                    : selectedElement !== null ? 
                        <div className='selection-details'>
                            <div className='selection-actions'>
                                <IconButton icon={faThLarge} label='Tiles' onClick={deSelectBoxes}/>
                                <IconButton icon={faTrash} label='Remove' onClick={removeElementHandler}/>
                            </div>
                            <div className='module-settings-tabs'>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'content' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('content')}>
                                    <IconButton icon={faPencil} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Content</div>
                                </div>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'style' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('style')}>
                                    <IconButton icon={faFillDrip} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Style</div>
                                </div>
                                <div role='button' 
                                        className={'module-settings-tab' + (settingsTab === 'advanced' ? ' selected' : '')}
                                        onClick={() => setSettingsTab('advanced')}>
                                    <IconButton icon={faTools} iconSize='24px' />
                                    <div className='module-settings-tab-label'>Advanced</div>
                                </div>
                            </div>
                            {settingsTab === 'content' &&
                                <>
                                    {getSelectedElement() && 
                                        <>
                                            {(() => {
                                                switch(getSelectedElement().type) {
                                                    case 'heading':
                                                        return <HeadingSettings elementId={selectedElement.element} />;
                                                    case 'paragraph':
                                                        return <ParagraphSettings elementId={selectedElement.element} />;
                                                    case 'text':
                                                        return <TextSettings elementId={selectedElement.element} />;
                                                    case 'image':
                                                        return <ImageSettings elementId={selectedElement.element} />;
                                                    default:
                                                        return null;
                                                }
                                            })()}
                                        </>
                                    }
                                </>
                            }
                            {settingsTab === 'style' && <StyleSettings element={{settings: {}}}/>}
                            {settingsTab === 'advanced' && <CommonSettings element={{settings: {}}} />}
                        </div>
                    :
                        <div className='tiles-view'>
                            <p>Drag and drop tiles into the paper area to create your dash card</p>
                            <div className='tiles'>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'section')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faRectangleLandscape} />
                                    </div>
                                    <div className='tile-label'>Section</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'image')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faImage} />
                                    </div>
                                    <div className='tile-label'>Image</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'heading')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faHeading} />
                                    </div>
                                    <div className='tile-label'>Heading</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'paragraph')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faParagraph} />
                                    </div>
                                    <div className='tile-label'>Paragraph</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'text')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faText} />
                                    </div>
                                    <div className='tile-label'>Text</div>
                                </div>
                                <div className='tile' draggable onDragStart={(e) => handleDragStart(e, 'line')}>
                                    <div className='tile-icon'>
                                        <FontAwesomeIcon icon={faHorizontalRule} />
                                    </div>
                                    <div className='tile-label'>Line</div>
                                </div>
                                
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function Page({page}) {
    return (
        <div className='mt-page'>
            {page.sections.map((section, sidx) => (
                <Section key={sidx} section={section} index={sidx} />
            ))}
        </div>
    )
}

export default WebsiteDesignStudio;