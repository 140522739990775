import Checkbox from 'components/checkbox';
import Toggle from 'components/toggle';
import { useVendors } from 'contexts/vendors';
import React, { useEffect, useState } from 'react';

function VendorDetails({vendor, onCancel, onSave}) {
    const { saveVendor } = useVendors();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [allowEmailMarketing, setAllowEmailMarketing] = useState(true);
    const [phone, setPhone] = useState('');
    const [taxId, setTaxId] = useState('');
    const [vendorType, setVendorType] = useState(0);
    const [processed, setProcessed] = useState(false);
    const [notes, setNotes] = useState('');

    useEffect(() => {
        if (vendor) {
            setName(vendor.name);
            setDescription(vendor.description);
            setAddress(vendor.address);
            setCity(vendor.city);
            setState(vendor.state);
            setZip(vendor.zip);
            setEmail(vendor.email);
            setAllowEmailMarketing(vendor.allowEmailMarketing);
            setPhone(vendor.phone);
            setTaxId(vendor.taxId);
            setVendorType(vendor.vendorType);
            setProcessed(vendor.processed);
            setNotes(vendor.notes);
        } else {
            setName('');
            setDescription('');
            setAddress('');
            setCity('');
            setState('');
            setZip('');
            setEmail('');
            setAllowEmailMarketing(true);
            setPhone('');
            setTaxId('');
            setVendorType(0);
            setProcessed(false);
            setNotes('');
        }
    }, [vendor])

    const saveHandler = () => {
        let data = {};
        if (vendor) {
            data = {...vendor, name, description, address, city, state, zip, 
                email, allowEmailMarketing, phone, taxId, vendorType, processed, notes};
            saveVendor(data);
        } else {
            data = {name, description, address, city, state, zip, 
                email, allowEmailMarketing, phone, taxId, vendorType, processed, notes};
            saveVendor(data);
        }
        onSave(data);
    }

    return (
        <div>
            <div>
                <label>Name</label><br/>
                <input type='text' value={name} onChange={e => setName(e.target.value)} maxLength={64} />
            </div>
            <div>
                <label>Description</label><br/>
                <textarea
                    value={description} 
                    onChange={e => setDescription(e.target.value)}
                    style={{height:'200px',width:'95%'}} 
                    maxLength='2048'></textarea>
            </div>
            <div>
                <label>Vendor Type</label>
                <select value={vendorType} onChange={e => setVendorType(e.target.value)}>
                    <option value='0'>Unknown</option>
                    <option value='1'>Swapper</option>
                    <option value='2'>Food</option>
                    <option value='3'>Merchandise</option>
                </select>
            </div>
            <div style={{display:'flex',flexFlow:'column'}}>
                <label>Mailing address</label>
                <input type='text' value={address} onChange={e => setAddress(e.target.value)} maxLength={128} placeholder='Address'/>
                <input type='text' value={city} onChange={e => setCity(e.target.value)} maxLength={64} placeholder='City'/>
                <input type='text' value={state} onChange={e => setState(e.target.value)} maxLength={2} placeholder='State'/>
                <input type='text' value={zip} onChange={e => setZip(e.target.value)} maxLength={10} placeholder='Zip'/>
            </div>
            <div>
                <label>Email</label><br/>
                <input type='email' value={email} onChange={e => setEmail(e.target.value)} maxLength={128} />
            </div>
            <div>
                <label>Allow email marketing</label>
                <Toggle checked={allowEmailMarketing} onChange={newVal => setAllowEmailMarketing(newVal)} />
            </div>
            <div>
                <label>Phone</label><br/>
                <input type='text' value={phone} onChange={e => setPhone(e.target.value)} maxLength={20} />
            </div>
            <div>
                <label>Tax ID</label><br/>
                <input type='text' value={taxId} onChange={e => setTaxId(e.target.value)} maxLength={20} />
            </div>
            {vendor && 
                <>
                    <hr/>
                    <div>
                        <label>Spaces</label>
                        <div>{vendor.elements.map(el => `${el.row}-${el.num}`).join(',')}</div>
                    </div>
                </>
            }
            <hr />
            <div>
                <label>Processed</label>
                <Checkbox checked={processed} 
                    onChange={(val) => setProcessed(val)} />
            </div>
            <div>
                <label>Notes (1024 characters max)</label><br/>
                <textarea 
                    value={notes} 
                    onChange={(evt) => setNotes(evt.target.value)} 
                    style={{height:'80px',width:'95%'}} 
                    maxLength='1024'></textarea>
            </div>
            <hr />
            <button onClick={onCancel}>Cancel</button>
            <button onClick={saveHandler}>{vendor ? 'Save' : 'Add'}</button>
        </div>
    )
}

export default VendorDetails;