import React, { useEffect, useState } from "react";
import { useCore } from "./core";

const EventCustomerContext = React.createContext(null);

function EventCustomersProvider({eventId, children}) {
    const { runAction } = useCore();
    const [loadingCustomers, setLoadingCustomers] = useState(true);
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        loadCustomers();
    }, [])

    const loadCustomers = async () => {
        setLoadingCustomers(true);
        await runAction('get_event_customers', {eventId}, response => {
            setCustomers(response.customers);
            setLoadingCustomers(false);
        })
    }

    const provider = {
        loadingCustomers,
        customers,
        loadCustomers,
    }

    return <EventCustomerContext.Provider value={provider}>{children}</EventCustomerContext.Provider>
}

function useEventCustomers() {
    const context = React.useContext(EventCustomerContext);
    if (!context) {
        throw new Error('useEventCustomers must be used within an EventCustomersProvider');
    }
    return context;
}

export { EventCustomersProvider, useEventCustomers }