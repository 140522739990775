import React, { useEffect, useState } from 'react';

const ScheduleContext = React.createContext(null);

function ScheduleProvider({jsonStr, onChange, children}) {
    const [days, setDays] = useState([]);

    useEffect(() => {
        if (jsonStr && jsonStr.length > 0) {
            try {
                const json = JSON.parse(jsonStr);
                console.log(json);
                setDays(json);
            } catch (err) {
                console.error('Error parsing json:' + err);
            }
        }
    }, [])

    useEffect(() => {
        if (onChange) {
            onChange(JSON.stringify(days));
        }
    }, [days])

    const addDay = (day) => {
        const maxId = days.length > 0 ? Math.max(...days.map(d => d.id)) : 0;
        day.id = maxId + 1;
        setDays([...days, day]);
    }

    const updateDay = (day) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            _days[idx].title = day.title;
            setDays(_days);
        }
    }

    const removeDay = (day) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            _days.splice(idx, 1);
            setDays(_days);
        }
    }

    const addItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            let maxId = data.length > 0 ? Math.max(data.map(i => i.id)) : 0;
            item.id = maxId + 1;
            data.push(item);
            _days[idx].data = [...data];
            setDays(_days);
        }
    }

    const updateItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            const itemIdx = data.findIndex(i => i.id === item.id);
            if (itemIdx >= 0) {
                data.splice(itemIdx, 1, item);
                _days[idx].data = [...data];
                setDays(_days);
            }
        }
    }

    const removeItem = (day, item) => {
        const idx = days.findIndex(d => d.id === day.id);
        if (idx >= 0) {
            let _days = [...days];
            let data = _days[idx].data;
            const itemIdx = data.findIndex(i => i.id === item.id);
            if (itemIdx >= 0) {
                data.splice(itemIdx, 1);
                _days[idx].data = [...data];
                setDays(_days);
            }
        }
    }

    const moveItem = (dayIndex, itemIndex, direction) => {
        let _days = [...days];
        let data = _days[dayIndex].data;
        if (direction === 'up' && itemIndex > 0) {
            [data[itemIndex], data[itemIndex - 1]] = [data[itemIndex - 1], data[itemIndex]];
        } else if (direction === 'down' && itemIndex < data.length - 1) {
            [data[itemIndex], data[itemIndex + 1]] = [data[itemIndex + 1], data[itemIndex]];
        }
        _days[dayIndex].data = [...data];
        setDays(_days);
    }

    const provider = {
        days,
        addDay,
        updateDay,
        removeDay,
        addItem,
        updateItem,
        removeItem,
        moveItem,
    }

    return <ScheduleContext.Provider value={provider}>{children}</ScheduleContext.Provider>
}

function useSchedule() {
    const context = React.useContext(ScheduleContext);
    if (!context) {
        throw new Error('useSchedule must be used within an ScheduleContext provider');
    }
    return context;
}

export { ScheduleProvider, useSchedule }