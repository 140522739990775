import React, { useEffect, useState } from "react";
import { useWebsite } from "../context/website";
import { ColorSetting } from "./controls";

function SectionSettings() {
    const { getSelectedSection, updateSelectedSection }  = useWebsite();
    const [section, setSection] = useState(getSelectedSection());
    const [color, setColor] = useState(section.sectionStyle?.color ? section.sectionStyle.color : '#000000');
    const [backgroundColor, setBackgroundColor] = useState(section.sectionStyle?.backgroundColor ? section.sectionStyle.backgroundColor : '#FFFFFF');

    useEffect(() => {
        updateSelectedSection(section);
    }, [section])

    const changePanelsHandler = (evt) => {
        const value = evt.target.value;
        let _section = {...section};
        if (_section.columns.length < value) {
            _section.columns.push({elements: [{type: 'paragraph', value: 'This is your new column', elementStyle:{fontSize:'14', fontWeight:'400'}}]});
        } else if (_section.columns.length > value) {
            _section.columns.pop();
        }
        setSection(_section);
    }
    
    useEffect(() => {
        setSection({...section, sectionStyle: {
            ...section.sectionStyle,
            color,
            backgroundColor
        }});
    }, [color, backgroundColor])

    return (
        <div>
            <div style={{display:'flex',flexFlow: 'column'}}>
            <label>Columns</label>
            <input type='number' value={section.columns.length} onChange={changePanelsHandler} min={1} max={5} />
            </div>
            <label>Color</label>
            <ColorSetting value={color} onChange={setColor} />
            <label>Background color</label>
            <ColorSetting value={backgroundColor} onChange={setBackgroundColor} />
        </div>
    )
}

export default SectionSettings;