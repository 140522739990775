import AmazingTable from 'components/amazingTable';
import { TableSetupProvider } from 'contexts/tableSetup';
import { useWebsites } from 'contexts/websites';
import React, { useState } from 'react';
import moment from 'moment';
import Modal from 'components/modal';
import DetailsPanel from 'components/detailsPanel';
import WebsiteDesignStudio from './websiteDesignStudio';
import baseWebsites from './baseWebsites';
import { WebsiteProvider } from './context/website';

function Websites() {
    const { loadingWebsites, websites, loadWebsites } = useWebsites();
    const [showNewWebsiteModal, setShowNewWebsiteModal] = useState(false);
    const [startingPoint, setStartingPoint] = useState('');
    const [showWebsite, setShowWebsite] = useState(false);
    const [website, setWebsite] = useState(null);
    const _templates = baseWebsites;

    const showWebsiteHandler = (row) => {}

    const removeWebsitesHandler = (rows) => {}

    const startWebsiteHandler = () => {
        setShowNewWebsiteModal(false);
        setWebsite(_templates[startingPoint]);
        setShowWebsite(true);
    }

    const cancelNewWebsiteHandler = () => {
        setShowNewWebsiteModal(false);
    }

    const detailsHideHandler = () => {
        setWebsite(null);
        setShowWebsite(false);
    }
    
    const columns = [
            {id: 'id', label:'ID', display: false, datum: 'id'},
            {id: 'name', label:'Name', display: true, datum: 'name'},
            {id: 'created', label: 'Created', display: true, formatter: obj => moment(obj.created).format('ddd, MMM Do YYYY')},
        ];

    return (
        <div>
            <TableSetupProvider tableName='websites' defaultColumns={columns}>
                <AmazingTable 
                    rows={websites}
                    onRefresh={loadWebsites}
                    onRowClick={showWebsiteHandler}
                    onCreate={() => setShowNewWebsiteModal(true)}
                    onCreateLabel='Add website'
                    onDelete={removeWebsitesHandler}
                    loading={loadingWebsites} />
            </TableSetupProvider>
            <Modal
                title='New website' showing={showNewWebsiteModal} 
                onHide={() => {setShowNewWebsiteModal(false)}}
                buttons={[
                    {type: 'primary', handler:() => startWebsiteHandler(), label: 'Start'},
                    {type: 'secondary', handler:() => cancelNewWebsiteHandler(), label: 'Cancel'}
            ]}>
                <p style={{width: '350px'}}>
                    Select which type of website you want to create.
                </p>
                <label>Type of website</label>
                <select value={startingPoint} onChange={e => setStartingPoint(e.target.value)}>
                    <option value=''>Blank</option>
                    <option value='carClub'>Car Club</option>
                    <option value='carShow'>Car Show</option>
                </select>
            </Modal>
            <DetailsPanel title='' showing={showWebsite} onHide={detailsHideHandler}>
                <div>
                {website && <WebsiteProvider website={website}><WebsiteDesignStudio /></WebsiteProvider>}
                </div>
            </DetailsPanel>
        </div>
    )
}

export default Websites;