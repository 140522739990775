import React from "react";
import { useCore } from "./core";

const TicketRedeptionContext = React.createContext(null);

function TicketRedeptionProvider({children}) {
    const { runAction } = useCore();

    const redeemTickets = async (ids, callback) => {
        await runAction('redeem_tickets', {ids}, () => {
            if (callback) {
                callback();
            }
        })
    }

    const provider = {
        redeemTickets
    }

    return <TicketRedeptionContext.Provider value={provider}>{children}</TicketRedeptionContext.Provider>
}

function useTicketRedemption() {
    const context = React.useContext(TicketRedeptionContext);
    if (!context) {
        throw new Error('useTicketRedeption must be used within a TicketRedeptionContext');
    }
    return context;
}

export { TicketRedeptionProvider, useTicketRedemption }