import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const VendorContext = React.createContext(null);

function VendorsProvider({dontLoadData = false, eventId, children}) {
    const { runAction } = useCore();
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        getVendors();
    }, [])

    const getVendors = async () => {
        if (!dontLoadData) {
            await runAction('get_all_vendors', {event: eventId}, (response) => {
                setVendors(response.vendors);
            })
        }
    }

    const saveVendor = async (vendorData, seat) => {
        if (vendorData.id) {
            const data = {eventId, vendor: {...vendorData, eventId}};
            await runAction('update_vendor', data, () => {
                getVendors();
            })
        } else {
            let data = {eventId, vendor: {...vendorData, eventId}}
            if (seat) {
                data.reservations = [seat];
            }
            await runAction('create_vendor', data, () => {
                getVendors();
            });
        }
    }

    const deleteVendors = async (ids) => {
        const data = {ids};
        await runAction('delete_vendors', data, () => {
            getVendors();
        })
    }

    const provider = {
        getVendors,
        vendors,
        saveVendor,
        deleteVendors,
    }

    return <VendorContext.Provider value={provider}>{children}</VendorContext.Provider>
}

function useVendors() {
    const context = React.useContext(VendorContext);
    if (!context) {
        throw new Error('useVendors must be used within a VendorsProvider');
    }
    return context;
}

export { VendorsProvider, useVendors }