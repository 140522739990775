import AmazingTabs from 'components/amazingTabs';
import { EventTicketsProvider } from 'contexts/eventTickets';
import React, { useState } from 'react';
import SoldTickets from './soldTickets';
import { TicketProvider } from 'contexts/ticket';
import TicketManager from './ticketManager';
import { TicketRedeptionProvider } from 'contexts/ticketRedemption';


const tabs = [
    {id: 'eventTicket', label: 'Ticket Types'},
    {id: 'tickets', label: 'Sold Tickets'},
]

function EventTickets({event}) {
    const [view, setView] = useState('eventTicket');

    return (
        <div className='app-portal'>
            <div className='title-bar'>
                <div className='title-bar-group'>
                    <div className='title'>Tickets : {event.name}</div>
                </div>
            </div>
            <div style={{padding:'50px'}}>
                <AmazingTabs tabs={tabs} selected={view} onClick={newView => setView(newView.id)} />
                {view === 'eventTicket' &&
                    <EventTicketsProvider eventId={event.id}>
                        <TicketManager />
                    </EventTicketsProvider>
                }
                {view === 'tickets' &&
                    <TicketRedeptionProvider eventId={event.id}>
                        <TicketProvider eventId={event.id}>
                            <SoldTickets eventId={event.id} />
                        </TicketProvider>
                    </TicketRedeptionProvider>
                }
            </div>
        </div>
    )
}

export default EventTickets;