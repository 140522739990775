import React, { useEffect, useState } from 'react';
import { useWebsite } from '../context/website';
import EditableDiv from 'apps/groupChat/EditableDiv';
import { ColorSetting } from './controls';

function TextSettings({elementId}) {
    const { getSelectedElement, updateSelectedElement } = useWebsite();
    const [element, setElement] = useState(getSelectedElement());
    const [value, setValue] = useState(element.value);
    const [fontSize, setFontSize] = useState(14);
    const [fontWeight, setFontWeight] = useState('400')
    const [textAlign, setTextAlign] = useState('left');

    useEffect(() => {
        console.log('elementId ', elementId)
        const newElement = getSelectedElement();
        setValue(newElement.value);
        if (newElement.elementStyle) {
            setFontSize(typeof newElement.elementStyle.fontSize === 'string' ? newElement.elementStyle.fontSize.replace('px', '') : newElement.elementStyle.fontSize);
            setFontWeight(newElement.elementStyle.fontWeight);
            setTextAlign(newElement.elementStyle.textAlign || 'left');
        }
        setElement(newElement);
    }, [elementId])

    useEffect(() => {
        updateSelectedElement(element);
    }, [element])

    useEffect(() => {
        setElement({...element, value});
    }, [value])

    useEffect(() => {
        setElement({...element, elementStyle: {fontSize: `${fontSize}px`, fontWeight, textAlign}});
    }, [fontSize, fontWeight, textAlign]);

    return (
        <>
            <label>Text</label>
            <EditableDiv value={value} onChange={html => setValue(html)}/>
            <label>Font Size</label>
            <input type='number' value={fontSize} onChange={e => setFontSize(e.target.value)} 
                min={9} max={100} step={1} />
            <label>Font Weight</label>
            <select value={fontWeight} onChange={e => setFontWeight(e.target.value)}>
                <option value='100'>Thin</option>
                <option value='200'>Extra Light</option>
                <option value='300'>Light</option>
                <option value='400'>Normal</option>
                <option value='500'>Medium</option>
                <option value='600'>Semi Bold</option>
                <option value='700'>Bold</option>
                <option value='800'>Extra Bold</option>
                <option value='900'>Black</option>
            </select>
            <label>Text alignment</label>
            <select value={textAlign} onChange={evt => setTextAlign(evt.target.value)}>
                <option value='left'>Left</option>
                <option value='center'>Center</option>
                <option value='right'>Right</option>
            </select>
        </>
    )
}

export default TextSettings;