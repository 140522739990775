import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Toggle from 'components/toggle';
import Schedule from './EventSchedule';
import { ScheduleProvider } from 'contexts/eventSchedule';
import { useEvent } from 'contexts/event';
import { useEmailTemplates } from 'contexts/emailTemplates';
import 'style/Longform.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faInfoCircle, faMobile } from '@fortawesome/pro-regular-svg-icons';
import CurrencyInput from 'react-currency-input-field';
import { useAuth } from 'contexts/auth';
import HiddenCanvas from 'components/hiddenCanvas';
import EventPanel from './eventPanel';

function ShowSettings(props) {
    const { tenant } = useAuth();
    const { updateEvent } = useEvent();
    const { templates } = useEmailTemplates();
    const [event, setEvent] = useState(null);
    const [eventType, setEventType] = useState('0');
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const section4 = useRef(null);
    const section5 = useRef(null);

    useEffect(() => {
        if (props.event) {
            setEvent({...props.event});
            setEventType(props.event.type);
        }
    }, [props.event])

    const lonformScrollHandler = (e) => {
        // unset all highlights
        document.querySelectorAll('h3').forEach(elem => elem.classList.remove('highlight'));
        // get the scroll top
        const isCarEvent = eventType == 1;
        const scrollTop = e.target.scrollTop;
        try {
            if (scrollTop === section1.current.offsetTop) {
                document.querySelector('h3:nth-of-type(1)').classList.add('highlight');
            } else if (scrollTop > section1.current.offsetTop && scrollTop <= section2.current.offsetTop) {
                document.querySelector('h3:nth-of-type(2)').classList.add('highlight');
            } else if (scrollTop > section2.current.offsetTop && scrollTop <= section3.current.offsetTop) {
                document.querySelector('h3:nth-of-type(3)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section3.current.offsetTop && scrollTop <= section4.current.offsetTop) {
                document.querySelector('h3:nth-of-type(4)').classList.add('highlight');
            } else if (isCarEvent && scrollTop > section4.current.offsetTop && scrollTop <= section5.current.offsetTop) {
                document.querySelector('h3:nth-of-type(5)').classList.add('highlight');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleChange = (key, value) => {
        let _event = {...event};
        _event[key] = value;
        setEvent(_event);
    }

    const addImageHandler = (imageData) => {
        let _event = {...event};
        _event.images = [..._event.images, {imageData, label: ''}];
        setEvent(_event);
    }

    const removeImageHandler = (imageIdx) => {
        let _event = {...event};
        _event.images.splice(imageIdx, 1);
        setEvent(_event);
    }

    const save = () => {
        var data = {
            id: event.id,
            name: event.name,
            type: event.type,
            availability: event.availability,
            startDate: event.startDate,
            endDate: event.endDate,
            description: event.description,
            schedule: event.schedule,
            registration: event.registration,
            reg_startdate: event.reg_startdate,
            reg_enddate: event.reg_enddate,
            reg_price: event.reg_price,
            regMemberDiscount: event.regMemberDiscount,
            reg_email: event.reg_email,
            images: event.images,
            vendors: event.vendors,
            reg_template: event.reg_template,
            workerSignup: event.workerSignup,
            workerStartDate: event.workerStartDate,
            workerEndDate: event.workerEndDate,
            workerChoices: event.workerChoices,
            workerEmail: event.workerEmail,
            workerTemplate: event.workerTemplate,
            votingStart: event.votingStart,
            votingEnd: event.votingEnd,
            votingInstructions: event.votingInstructions,
            typedMakeAndModel: event.typedMakeAndModel,
            agreement: event.agreement,
            ticketLabel: event.ticketLabel,
            preEventLine1: event.preEventLine1,
            preEventLine2: event.preEventLine2,
            participantMessage: event.participantMessage,
            vendorMessage: event.vendorMessage,
            ticketMessage: event.ticketMessage,
            workerMessage: event.workerMessage
        };
        updateEvent(data, () => props.onBack());
    }

    const eventTypeChangeHandler = (e) => {
        setEventType(e.target.value);
        handleChange('type', e.target.value);
    }

    return (
        <div className='longform_outer'>
            {event &&
            <>
            <div className='longform_section_links_outer'>
                <div className='longform_section_links_inner'>
                    <h3 className='highlight'><a href='#1'>Event Information</a></h3>
                    <h3><a href='#2'>Media</a></h3>
                    {eventType == 1 &&
                    <>
                        <h3><a href='#3'>Schedule</a></h3>
                        <h3><a href='#4'>Participants Registration</a></h3>
                        <h3><a href='#5'>Worker Signup</a></h3>
                    </>
                    }
                </div>
            </div>
            <div className='longform_sections_outer' onScroll={lonformScrollHandler}>
                <div className='longform_sections_inner'>
                    <section id='1' ref={section1}>
                        <h4>Event Information (Required)</h4>
                        <label>Name</label>
                        <input type='text' value={event.name} onChange={(evt) => handleChange('name', evt.target.value)} maxLength='64' />
                        <label>Type</label>
                        <select value={eventType} onChange={eventTypeChangeHandler}>
                            <option value='0'>Basic (Ticket Sales Only)</option>
                            <option value='1'>Competition Show (Has Voting)</option>
                        </select>
                        <label>Availability</label>
                        <select value={event.availability} onChange={e => handleChange('availability', e.target.value)}>
                            <option value='0'>Open to the public</option>
                            <option value='1'>Members only</option>
                        </select>
                        <label>The first day of this event</label>
                        <DatePicker selected={moment(event.startDate).toDate()} onChange={(date) => handleChange('startDate', date.getTime())}/>
                        <label>The last day of this event</label>
                        <DatePicker selected={moment(event.endDate).toDate()} onChange={(date) => handleChange('endDate', date.getTime())} />
                        <label>Description (2048 character max)</label>
                        <textarea
                            defaultValue={event.description} 
                            onChange={(evt) => handleChange('description', evt.target.value)}
                            style={{height:'200px',width:'100%'}} 
                            maxLength='2048'></textarea>
                        <label>Purchase agreement policy (10,000 character max)</label>
                        <textarea
                            defaultValue={event.agreement} 
                            onChange={(evt) => handleChange('agreement', evt.target.value)}
                            style={{height:'200px',width:'100%'}} 
                            maxLength='10000'></textarea>
                        <label>Tickets Label</label>
                        <p style={{margin:'0'}}><FontAwesomeIcon icon={faInfoCircle} />You can change the tickets label that appears on the purchasing view if you are using 
                            tickets for a purpose other than actual tickets. &quot;Tickets&quot; will be used if this is blank.</p>
                        <input type='text' value={event.ticketLabel} onChange={(evt) => handleChange('ticketLabel', evt.target.value)} maxLength='64' />
                        {event.type === 1 &&
                            <>
                                <hr/>
                                <label>Public event panel view</label>
                                <EventPanel event={event} onUpdate={handleChange} />
                            </>
                        }
                        {event.type === 1 &&
                            <>
                                <hr/>
                                <label>Event viewer (BETA)</label>
                                <p>This is the experience for everyone who attends your event. Share the provided link from your public website 
                                    or using a QR code at your event. This is optimized to be a mobile app type experience without the need for 
                                    a native ios/android app.
                                </p>
                                <div>
                                    <span style={{fontWeight:'bold'}}>{`${tenant.subdomain}.middletwin.com/portal/event/${event.id}`} 
                                        <a href={`https://${tenant.subdomain}.middletwin.com/portal/event/${event.id}`} target='_blank' rel='noopener noreferrer'>
                                            <FontAwesomeIcon icon={faExternalLink} />
                                        </a>
                                    </span>
                                </div>
                                <p>Thoughts:
                                    <ul>
                                        <li>The event panel in your member portal would have a button to allow the user to navigate to this experience</li>
                                        <li>you can have signs at your event with a QA code to this link so everyone at the show</li>
                                        <li>Adding a view with links to join the club, follow on social, etc...</li>
                                        <li>Adding a view to allow uploading photos from the event</li>
                                    </ul>
                                </p>
                                <p>Please send an email to wade.girard@middletwin.com with your thoughts and what you would want to see here.</p>
                            </>
                        }
                    </section>

                    <hr/>
                    <section id='2' ref={section2}>
                        <h4>Media (Optional)</h4>
                        <p>Add an image to the event that will be used in the event listings. For best results use an image that is 
                            400 x 400 pixels in size. You can use PNG or JPEG format images.
                        </p>
                        <HiddenCanvas buttonTitle='Add image' width={400} height={400} onChange={addImageHandler}/>
                        <div className='image-viewer'>
                            {event.images.map((image, idx) => 
                                <div key={idx} className='image-view-container'>
                                    <div className='image-view-image' style={{backgroundImage:`url(${image.imageData})`}} />
                                    <div className='image-view-buttons'>
                                        <button className='linkButton' onClick={() => removeImageHandler(idx)}>Remove</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                    
                    {eventType == 1 &&
                        <>
                            <hr/>
                            <section id='3' ref={section3}>
                                <h4><FontAwesomeIcon icon={faMobile} /> Schedule (optional)</h4>
                                <ScheduleProvider jsonStr={event.schedule} onChange={jsonStr => handleChange('schedule', jsonStr)}>
                                    <Schedule />
                                </ScheduleProvider>
                            </section>

                            <hr/>
                            <section id='4' ref={section4}>
                                <h4>Participants Registration (Optional)</h4>
                                <label>Online Participant Registration</label>
                                <label>Enable</label><Toggle checked={event.registration} 
                                    onChange={newVal => handleChange('registration', newVal)}/>
                                <label>Start Date</label> <DatePicker 
                                    selected={moment(event.reg_startdate).toDate()}
                                    onChange={(date) => handleChange('reg_startdate', date.getTime())} />
                                <label>End Date</label> <DatePicker 
                                    selected={moment(event.reg_enddate).toDate()}
                                    onChange={(date) => handleChange('reg_enddate', date.getTime())} />
                                <label>Price</label>
                                <CurrencyInput prefix='$'
                                    value={event.reg_price} 
                                    onValueChange={(value) => handleChange('reg_price', value)} />
                                <label>Member Discount</label>
                                <CurrencyInput prefix='$' 
                                    value={event.regMemberDiscount} 
                                    onValueChange={(value) => handleChange('regMemberDiscount', value)} />
                                <label>New Notification Email</label> <input type='text' 
                                    value={event.reg_email}
                                    onChange={(evt) => handleChange('reg_email', evt.target.value)} />
                                <label>Response Template</label>
                                <select 
                                    value={event.reg_template}
                                    onChange={(evt) => handleChange('reg_template', evt.target.value)}>
                                        <option value='0'>None</option>
                                        {templates.map(et => <option value={et.id} key={et.id}>{et.name}</option>)}
                                </select>
                            </section>

                            <hr />
                            <section id='5' ref={section5}>
                                <h4>Worker Signup (Optional)</h4>
                                <label>Online Worker Signup</label>
                                <label>Enable</label><Toggle checked={event.workerSignup} 
                                    onChange={newVal => handleChange('workerSignup', newVal)}/>
                                <label>Start Date</label> <DatePicker 
                                    selected={moment(event.workerStartDate).toDate()}
                                    onChange={(date) => handleChange('workerStartDate', date.getTime())} />
                                <label>End Date</label> <DatePicker 
                                    selected={moment(event.workerEndDate).toDate()}
                                    onChange={(date) => handleChange('workerEndDate', date.getTime())} />
                                <label>Free gift choices (comma separated list of choices)</label>
                                <textarea style={{height:'100px'}} value={event.workerChoices} 
                                    onChange={e => handleChange('workerChoices', e.target.value)} maxLength={512}
                                    placeholder='Small show shirt, Medium show shirt, Large show shirt' />
                                <label>New Worker Signup Email</label>
                                <input type='text' value={event.workerEmail}
                                    onChange={(evt) => handleChange('workerEmail', evt.target.value)} />
                                <label>Response Template</label>
                                <select 
                                    value={event.workerTemplate}
                                    onChange={(evt) => handleChange('workerTemplate', evt.target.value)}>
                                        <option value='0'>None</option>
                                        {templates.map(et => <option value={et.id} key={et.id}>{et.name}</option>)}
                                </select>
                            </section>
                        </>
                    }
                    
                    <div className='button-container'>
                        <button className='linkButton' onClick={props.onBack}>Cancel</button>
                        <button onClick={save}>Save event</button>
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default ShowSettings;
