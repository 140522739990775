import { faSync } from '@fortawesome/pro-regular-svg-icons';
import AmazingTable from 'components/amazingTable';
import IconButton from 'components/iconButton';
import { useTicket } from 'contexts/ticket';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { TableSetupProvider } from 'contexts/tableSetup';
import Modal from 'components/modal';
import Toggle from 'components/toggle';
import { formatPhoneNumber, toSpliced } from '_base';
import SidePanel from 'components/sidePanel';
import WaitButton from 'components/waitButton';
import { useTicketRedemption } from 'contexts/ticketRedemption';

function SoldTickets({eventId}) {
    const navigate = useNavigate();
    const { tickets, loadTickets } = useTicket();

    const openTickets = () => {
        const path = '/tickets/' + eventId;
        navigate(path);
    }

    return (
        <div className='schedules-container'>
            <div className='schedules-header'>
                <IconButton icon={faSync} label='Reload tickets' onClick={loadTickets} />
                <button onClick={openTickets}>Print tickets</button>
            </div>
            {tickets.map(ticket => <TicketGroup key={ticket.id} eventTicket={ticket} />)}
        </div>
    )
}

export default SoldTickets;

function TicketGroup({eventTicket}) {
    const { loadingTickets, deleteTickets, pickRandomTicket, randomTicket, loadTickets } = useTicket();
    const [tickets, setTickets] = useState([]);
    const [showRandomPick, setShowRandomPick] = useState(false);
    const addTicketPanelRef = useRef(null);
    const { redeemTickets } = useTicketRedemption();

    useEffect(() => {
        if (eventTicket) {
            setTickets(eventTicket.tickets);
        }
    }, [eventTicket])

    const columns = [
        {id: 'id', label: 'ID', display: false, style: {width:'50px'}, datum: 'id'},
        {id: 'purchased', label: 'Purchased', display: true, style: {width:'100px'}, formatter: obj => moment(obj.purchased).format('YYYY-MM-DD')},
        {id: 'redeemed', label: 'Redeemed', display: true, style: {width:'100px'}, formatter: obj => obj.redeemed ? moment(obj.purchased).format('YYYY-MM-DD') : 'No'},
        {id: 'name', label: 'Name', display: true, datum: 'name'},
        {id: 'address', label: 'Address', display: false, datum: 'address'},
        {id: 'city', label: 'City', display: false, datum: 'city'},
        {id: 'state', label: 'State', display: false, datum: 'state'},
        {id: 'zip', label: 'Zip', display: false, datum: 'zip'},
        {id: 'email', label: 'Email', display: true, datum: 'email'},
        {id: 'phone', label: 'Phone', display: true, formatter: obj => formatPhoneNumber(obj.phone)},
        {id: 'allowEmailMarketing', label: 'Ok to email', display: true, style: {width:'50px'}, formatter: obj => obj.allowEmailMarketing ? 'Yes' : 'No'},
        {id: 'values', label: 'Options', display: true, formatter: obj => obj.values.map(item => item.value).join(', ')}
    ]

    const addTicketDoneHandler = () => {
        addTicketPanelRef.current.hide();
    }

    const handleDeleteEventTicket = (rows) => {
        const ids = rows.map(row => row.id);
        deleteTickets(ids);
    }

    const exportTicketsHandler = () => {
        var csvData = [['purchased','name', 'address', 'city', 'state', 'zip', 'email', 'phone', 'okToEmail', 'values']];
        tickets.forEach(t => csvData.push([moment(t.purchased).format('YYYY-MM-DD'), t.name, t.address, t.city, t.state, t.zip, t.email, t.phone, t.allowEmailMarketing ? 'Yes' : 'No', t.values.map(item => item.value).join(' ')]));

        let csvContent = 'data:text/csv;charset=utf-8,' 
            + csvData.map(e => e.join(',')).join('\n');

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const randomPickHandler = () => {
        setShowRandomPick(true);
        pickRandomTicket(eventTicket.id);
    }

    const redeemHandler = (rows) => {
        const ids = rows.map(row => row.id);
        redeemTickets(ids, () => {loadTickets()});
    }

    return (
        <div className='ticketGroup'>
            <div style={{margin:'10px auto',border:'1px solid gray',borderRadius:'5px',padding:'20px',width:'200px',textAlign:'center'}}>{`${tickets.length} Total`}</div>
            <h4>{eventTicket.description}</h4>
            <TableSetupProvider tableName={`tickets-${eventTicket.id}`} defaultColumns={columns}>
                <AmazingTable 
                    rows={tickets}
                    loading={loadingTickets}
                    onCreate={() => addTicketPanelRef.current.show()}
                    onCreateLabel='Add ticket'
                    onExport={exportTicketsHandler}
                    onDelete={handleDeleteEventTicket}
                    onRandom={randomPickHandler}
                    onRedeem={redeemHandler}
                />
            </TableSetupProvider>
            <SidePanel title='Add ticket' ref={addTicketPanelRef}>
                <AddTicketDialog eventTicket={eventTicket} onDone={addTicketDoneHandler} />
            </SidePanel>
            <Modal 
                title='Random pick' showing={showRandomPick} 
                onHide={() => setShowRandomPick(false)}
                buttons={[
                    {
                        type: 'primary',
                        handler: () => randomPickHandler(),
                        label: 'Pick again'
                    },
                    {   type: 'secondary', 
                        handler: () => setShowRandomPick(false),
                        label:'Close'
                    }
                ]} 
            >
                {randomTicket && 
                    <>
                        <p style={{width:'400px'}}>This ticket was randomly selected from all the tickets purchased.
                            Click the Pick again button to select a different ticket.
                        </p>
                        <ul style={{listStyle: 'none'}}>
                            <li>Name: {randomTicket.name}</li>
                            <li>Email: {randomTicket.email}</li>
                            <li>Purchased: {moment(randomTicket.purchased).format('ddd MMM DD, yyyy')}</li>
                        </ul>
                    </>
                }
            </Modal>
        </div>
    )
}

function AddTicketDialog({eventTicket, onDone}) {
    const { createTicket, creatingTicket } = useTicket();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [allowEmailMarketing, setAllowEmailMarketing] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [values, setValues] = useState([]);

    const updateTicketValue = (ticketIndex, value) => {
        const valueIdx = values.findIndex(v => v.value.optionId === value.optionId && v.ticketIndex === ticketIndex);
        console.log('optionId:', value.optionId, ', valueIdx:', valueIdx);
        if (valueIdx == -1) {
            setValues([...values, {ticketIndex, value}]);
        } else {
            setValues(toSpliced(values, valueIdx, 1, {ticketIndex, value}));
        }
    }

    const reset = () => {
        setName('');
        setAddress('');
        setCity('');
        setState('');
        setZip('');
        setEmail('');
        setPhone('');
        setAllowEmailMarketing(true);
        setQuantity(1);
        setValues([]);
    }

    const createTicketHandler = () => {
        const data = {
            ticket: eventTicket, quantity, values
        }
        const newTicketData = {name, address, city, state, zip, email, phone, allowEmailMarketing, tickets: [data]};
        createTicket(newTicketData, () => {
            reset();
            onDone();
        });
    }

    return (
        <div>
            <p>You are adding a new <span style={{fontWeight:'600'}}>{eventTicket.description}</span> ticket</p>
            <p>Provide the name, email and ticket choices. When you click save an email will be sent to the email address for the ticket recipient.</p>
            <div style={{display:'flex',flexFlow:'column nowrap'}}>
                <label>Name</label>
                <input type='text' value={name} onChange={evt => setName(evt.target.value)} maxLength='64'/>
                <label>Address</label>
                <input type='text' value={address} onChange={evt => setAddress(evt.target.value)} maxLength='128'/>
                <label>City</label>
                <input type='text' value={city} onChange={evt => setCity(evt.target.value)} maxLength='64'/>
                <label>State</label>
                <input type='text' value={state} onChange={evt => setState(evt.target.value)} maxLength='32'/>
                <label>Zip</label>
                <input type='text' value={zip} onChange={evt => setZip(evt.target.value)} maxLength='12'/>
                <label>Email</label>
                <input type='text' value={email} onChange={evt => setEmail(evt.target.value)} maxLength='128'/>
                <label>Phone</label>
                <input type='text' value={phone} onChange={evt => setPhone(evt.target.value)} maxLength='12' />
                <label>Allow email marketing</label>
                <Toggle checked={allowEmailMarketing} onChange={newVal => setAllowEmailMarketing(newVal)} />
                <label>Quantity</label>
                <input type='number' value={quantity} onChange={evt => setQuantity(evt.target.value)} min={0} max={eventTicket.limit} step={1} />
                {eventTicket.options.length > 0 && quantity > 0 &&
                    <>
                        {[...Array(parseInt(quantity))].map((_, ticketIndex) => 
                            <div key={`ticket-${ticketIndex}`}>
                                {eventTicket.options.map((opt) => 
                                    <TicketValueItem 
                                        key={`${opt.id}-${ticketIndex}`} 
                                        option={opt} 
                                        onChange={value => updateTicketValue(ticketIndex, value)} />
                                )}
                            </div>
                        )}
                    </>
                }
            </div>
            <div classNamne='buttonsHolder'>
                <WaitButton onClick={createTicketHandler} label='Save' waitMode={creatingTicket} />
                <button onClick={() => {reset();onDone();}}>Cancel</button>
            </div>
        </div>
    )
}

function TicketValueItem({option, onChange}) {
    const [value, setValue] = useState({value: '', optionId: option.id});

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <div className='ticket-value'>
            <div className='ticket-value-label'>
                <div>{option.label}</div>
                {option.subLabel.length > 0 &&
                    <div className='sub-label'> ({option.subLabel})</div>
                }
            </div>
            {option.type === 1 ?
                <input type='text' value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})} maxLength={64} />
                : option.type === 2 ?
                <select value={value.value} onChange={e => setValue({value: e.target.value, optionId: option.id})}>
                    <option value=''>-Choose one-</option>
                    {option.choices.map(choice =>
                        <option key={choice.id} value={choice.label}>{choice.label}</option>
                    )}
                </select>
                : null
            }
        </div>
    )
}