import React, { useEffect, useState } from "react";
import { SliderSetting, ButtonSetting, ColorSetting, TableSetting } from "./controls";
import { useWebsite } from "../context/website";

function CommonSettings() {
    const { getSelectedElement, updateSelectedElement } = useWebsite();
    const [element, setElement] = useState(getSelectedElement());
    const [marginTop, setMarginTop] = useState(element.elementStyle?.marginTop ? parseInt(element.elementStyle.marginTop.replace('px', '')) : 0);
    const [marginRight, setMarginRight] = useState(element.elementStyle?.marginRight ? parseInt(element.elementStyle.marginRight.replace('px', '')) : 0);
    const [marginBottom, setMarginBottom] = useState(element.elementStyle?.marginBottom ? parseInt(element.elementStyle.marginBottom.replace('px', '')) : 0);
    const [marginLeft, setMarginLeft] = useState(element.elementStyle?.marginLeft ? parseInt(element.elementStyle.marginLeft.replace('px', '')) : 0);
    const [paddingTop, setPaddingTop] = useState(element.elementStyle?.paddingTop ? parseInt(element.elementStyle.paddingTop.replace('px', '')) : 0);
    const [paddingRight, setPaddingRight] = useState(element.elementStyle?.paddingRight ? parseInt(element.elementStyle.paddingRight.replace('px', '')) : 0);
    const [paddingBottom, setPaddingBottom] = useState(element.elementStyle?.paddingBottom ? parseInt(element.elementStyle.paddingBottom.replace('px', '')) : 0);
    const [paddingLeft, setPaddingLeft] = useState(element.elementStyle?.paddingLeft ? parseInt(element.elementStyle.paddingLeft.replace('px', '')) : 0);
    const [border, setBorder] = useState('none');
    const [borderTop, setBorderTop] = useState(element.elementStyle?.borderTop ? parseInt(element.elementStyle.borderTop.replace('px', '')) : 0);
    const [borderRight, setBorderRight] = useState(element.elementStyle?.borderRight ? parseInt(element.elementStyle.borderRight.replace('px', '')) : 0);
    const [borderBottom, setBorderBottom] = useState(element.elementStyle?.borderBottom ? parseInt(element.elementStyle.borderBottom.replace('px', '')) : 0);
    const [borderLeft, setBorderLeft] = useState(element.elementStyle?.borderLeft ? parseInt(element.elementStyle.borderLeft.replace('px', '')) : 0);
    const [borderColor, setBorderColor] = useState(element.elementStyle?.borderColor ? element.elementStyle.borderColor : '#000000');
    const [borderRadius, setBorderRadius] = useState(element.elementStyle?.borderRadius ? parseInt(element.elementStyle.borderRadius.replace('px', '')) : 0);

    useEffect(() => {
        updateSelectedElement(element);
    }, [element])

    useEffect(() => {
            setElement({...element, elementStyle: {
                ...element.elementStyle,
                marginTop: marginTop + 'px', 
                marginRight: marginRight + 'px',
                marginBottom: marginBottom + 'px', 
                marginLeft: marginLeft + 'px',
                paddingTop: paddingTop + 'px', 
                paddingRight: paddingRight + 'px', 
                paddingBottom: paddingBottom + 'px', 
                paddingLeft: paddingLeft + 'px', 
                border, 
                borderTop: borderTop + 'px', 
                borderRight: borderRight + 'px', 
                borderBottom: borderBottom + 'px', 
                borderLeft: borderLeft + 'px',
                borderColor, 
                borderRadius: borderRadius + 'px'}});
        }, [marginTop, marginRight, marginBottom, marginLeft,
                paddingTop, paddingRight, paddingBottom, paddingLeft, border, borderTop, borderRight, borderBottom, borderLeft,
            borderColor, borderRadius])

    return (
        <div>
            <h5>Spacing</h5>
            <label>Margin</label>
            <TableSetting 
                top={marginTop} right={marginRight} bottom={marginBottom} left={marginLeft}
                onChangeTop={setMarginTop} onChangeRight={setMarginRight} onChangeBottom={setMarginBottom} onChangeLeft={setMarginLeft}
                units='px'
            />
            <label>Padding</label>
            <TableSetting 
                top={paddingTop} right={paddingRight} bottom={paddingBottom} left={paddingLeft}
                onChangeTop={setPaddingTop} onChangeRight={setPaddingRight} onChangeBottom={setPaddingBottom} onChangeLeft={setPaddingLeft}
                units='px'
            />
            <h5>Border</h5>
            <label>Border style</label>
            <ButtonSetting 
                buttons={
                    [
                        {label:'None', value:'none'},
                        {label:'Solid', value:'solid'},
                        {label:'Dashed', value:'dashed'},
                        {label:'Double', value:'double'},
                        {label:'Dotted', value:'dotted'}
                    ]
                }
                value={border}
                onChange={setBorder}
            />
            <label>Border width</label>
            <TableSetting 
                top={borderTop} right={borderRight} bottom={borderBottom} left={borderLeft}
                onChangeTop={setBorderTop} onChangeRight={setBorderRight} onChangeBottom={setBorderBottom} onChangeLeft={setBorderLeft}
                units='px'
            />
            <label>Border color</label>
            <ColorSetting value={borderColor} onChange={setBorderColor} />
            <label>Border radius</label>
            <SliderSetting value={borderRadius} onChange={setBorderRadius} max={30} unit='px' />
        </div>
    )
}

export default CommonSettings;