import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'style/main.scss';
//import * as serviceWorker from './serviceWorker';
import Root from 'routes/root';
import { AuthProvider } from 'contexts/auth';
import { TokenProvider } from 'contexts/token';
import Login from 'routes/login/login';
import Logout from 'routes/logout';
import Signup from 'routes/signup/signup';
import AdminRoot from 'routes/admin/adminRoot';
import { CoreProvider } from 'contexts/core';

import Settings, {loader as settingsLoader} from 'routes/settings';
import Accounts from 'routes/systemAdmin/accounts/accounts';
import Club, {loader as clubLoader } from 'routes/admin/club';
import Dashboard from 'routes/admin/dashboard/dashboard';
import DashCard, {loader as dashcardLoader} from 'routes/dashCard';
import Email, {loader as EmailLoader} from 'routes/admin/email';
import EmailStats, {loader as EmailStatsLoader} from 'routes/admin/emailStats';
import EquipmentRentals from 'routes/systemAdmin/equipmentRentals';
import ErrorPage from 'error-page';
import Events, {loader as EventsLoader} from 'routes/admin/events';
import Finance, {loader as financeLoader} from 'routes/admin/finance/Finance';
import GroupAdmin from 'apps/groupAdmin/groupAdmin';
import GroupChat from 'apps/groupChat/GroupChat';
import GroupDashboard from 'apps/groupDashboard/dashboard';
import Invoice, {loader as invoiceLoader} from 'routes/invoice/invoice';
import LoginsApp from 'routes/systemAdmin/logins/loginsApp';
import CampaignLogs, {loader as campaignLogsLoader} from 'routes/admin/email/campaignLogs';
import MailingLabels, {loader as mailingLabelsLoader} from 'routes/mailingLabels/mailingLabels';
import MemberCards, {loader as memberCardsLoader} from 'routes/memberCards/MemberCards';
import Membership, {loader as membershipLoader} from 'routes/admin/club';
import Monitoring from 'apps/monitoring/Monitoring';
import Landing from 'routes/landing';
import PhoneApp from 'apps/phone/phone';
import Redeem, {loader as redeemLoader} from 'routes/redeem/Redeem';
import RegistrationCard, {loader as RegistrationCardLoader} from 'routes/registrationCard';
import Results, {loader as resultsLoader}from 'apps/results/results';
import Roster, {loader as rosterLoader} from 'routes/roster/roster';
import Services from 'routes/systemAdmin/services';
import Setup from 'routes/setup/setup';
import ShowLanding, {loader as ShowLoader} from 'routes/admin/events/showLanding';
import Store, { loader as storeLoader } from 'routes/admin/club/store';
import Stripe from 'routes/systemAdmin/stripe';
import System from 'routes/system/system';
import Ticket, {loader as ticketLoader} from 'routes/ticket/Ticket';
import Tickets, {loader as ticketsLoader} from 'routes/tickets/Tickets';
import Unsubscribe, {loader as unsubscribeLoader} from 'routes/unsubscribe';
import Video from 'apps/video/video';
import Vote, {loader as voteLoader} from 'routes/vote/vote';
import VoteCodes, {loader as voteCodesLoader} from 'routes/voteCodes/voteCodes';
import VoterBallots, {loader as voterBallotsLoader} from 'routes/voterBallots';
import VCard, {loader as vcardLoader} from 'routes/vcard/VCard';
import Website, {loader as websiteLoader} from 'routes/admin/website';
// contexts
import { AnalyticsProvider } from 'contexts/analytics';
import { TenantsProvider } from 'contexts/tenants';
import { AffiliatesProvider } from 'contexts/affiliates';
import { HealthProvider } from 'contexts/health';
import { StoreProvider } from 'contexts/store';
import reportWebVitals from './reportWebVitals';
import { PageProvider } from 'contexts/page';
import { SetupAccountProvider } from 'contexts/setupAccount';
import { EquipmentRentalProvider } from 'contexts/equipmentRental';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {path: 'landing', element: <Landing />},
            {path: 'login', element: <Login />},
            {path: 'logout', element: <Logout />},
            {path: 'signup', element: <SetupAccountProvider><Signup /></SetupAccountProvider>},
            {path: 'setup', element: <AuthProvider><Setup /></AuthProvider>},
            {path: 'system', element: <System />},
            {path: 'labels/:type/:tenant/:event', element: <MailingLabels />, loader: mailingLabelsLoader},
            {path: 'dashcard/:dashCardId/:tenant/:event/:car', element: <DashCard />, loader: dashcardLoader},
            {path: 'registrationcard/:tenant/:event/:car/:page', element: <TokenProvider><PageProvider><RegistrationCard /></PageProvider></TokenProvider>, loader: RegistrationCardLoader},
            {path: 'results/:event', element: <Results />, loader: resultsLoader},
            {path: 'roster/:tenant/:type', element: <Roster />, loader: rosterLoader},
            {path: 'tickets/:event', element: <Tickets />, loader: ticketsLoader},
            {path: 'ticket/:tenant/:id', element: <Ticket />, loader: ticketLoader},
            {path: 'redeem/:tenant/:id', element: <Redeem />, loader: redeemLoader},
            {path: 'membercards/:tenant/:type', element: <MemberCards />, loader: memberCardsLoader},
            {path: 'invoice/:tenant/:invoice', element: <Invoice />, loader: invoiceLoader},
            {path: 'unsubscribe/:email/:tenantId?', element: <Unsubscribe />, loader: unsubscribeLoader},
            {path: 'vcard/:tenant', element: <VCard />, loader: vcardLoader},
            {path: 'vote/:tenant/:event/:car', element: <Vote />, loader: voteLoader},
            {path: 'voterballots/:eventid/:subdomain/:batch', element: <TokenProvider><VoterBallots /></TokenProvider>, loader: voterBallotsLoader},
            {path: 'votecodes/:tenant/:event/:start', element: <VoteCodes />, loader: voteCodesLoader},
        ],
    },{
        path: '/admin',
        element: <AdminRoot />,
        children: [
            {path: 'accounts', element: <TenantsProvider><AffiliatesProvider><Accounts /></AffiliatesProvider></TenantsProvider>},
            {path: 'admin', element: <Settings />, loader: settingsLoader},
            {path: 'admin/:view', element: <Settings />, loader: settingsLoader},
            {path: 'club', element: <Club />, loader: clubLoader},
            {path: 'club/:view', element: <Club />, loader: clubLoader},
            {path: 'club/store/:view', element: <StoreProvider><Store /></StoreProvider>, loader: storeLoader},
            {path: 'club/membership/:view', element: <Membership />, loader: membershipLoader},
            {path: 'club/membership/:view/:tag', element: <Membership />, loader: membershipLoader},

            {path: 'dashboard',element: <AnalyticsProvider><HealthProvider><Dashboard /></HealthProvider></AnalyticsProvider>},
            {path: 'email', element: <Email />, loader: EmailLoader},
            {path: 'email/:view', element: <Email />, loader: EmailLoader},
            {path: 'email/stats/:campaign', element: <EmailStats />, loader: EmailStatsLoader},
            {path: 'email/logs/:campaign', element: <CampaignLogs />, loader: campaignLogsLoader},
            {path: 'equipmentrentals', element: <EquipmentRentalProvider><EquipmentRentals /></EquipmentRentalProvider>},
            {path: 'events', element: <Events />, loader: EventsLoader},
            {path: 'events/:view', element: <Events />, loader: EventsLoader},
            {path: 'events/show/:showId', element: <ShowLanding />, loader: ShowLoader},
            {path: 'events/show/:showId/:view', element: <ShowLanding />, loader: ShowLoader},
            {path: 'finance/:view', element: <Finance />, loader: financeLoader},
            {path: 'groupchat', element: <GroupChat />},
            {path: 'logins', element: <LoginsApp />},
            {path: 'phone', element: <PhoneApp />},
            {path: 'services', element: <Services />},
            {path: 'stripe', element: <Stripe />},
            {path: 'video', element: <Video />},
            {path: 'website', element: <Website/> },
            {path: 'website/:siteId', element: <Website/>, loader: websiteLoader},
        ]
    },{
        path: '/group',
        element: <AdminRoot />,
        children: [
            {path: 'accounts', element: <TenantsProvider><AffiliatesProvider><Accounts /></AffiliatesProvider></TenantsProvider>},
            {path: 'admin', element: <GroupAdmin />},
            {path: 'dashboard', element: <GroupDashboard />},
            {path: 'monitoring', element: <Monitoring />},
        ]
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TokenProvider>
        <CoreProvider>
            <RouterProvider router={router} />
        </CoreProvider>
    </TokenProvider>
  </React.StrictMode>
);
if (module.hot) {
    module.hot.accept();
  }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
